import React from 'react'
import {SVGProps} from '@sha/react-fp'
export default (props: SVGProps) => {
    return (
        <svg width="183" height="225" viewBox="0 0 183 225" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M60.8787 63.2542L51.8787 66.9358C51.2207 67.2053 50.5 66.7219 50.5 66.011V51.1184C50.5 50.7395 50.7141 50.3928 51.0527 50.2239L60.0527 45.7239C60.7178 45.3918 61.5 45.8752 61.5 46.6184V62.3293C61.5 62.5374 61.4353 62.7346 61.3218 62.8987C61.2139 63.054 61.0618 63.179 60.8787 63.2542Z" fill="#3781FF"/>
            <path d="M80.7961 79.2512L70.7961 81.3342C70.5654 81.3821 70.3672 81.5071 70.2263 81.6799C70.084 81.8538 70 82.0754 70 82.3137V100.457C70 101.027 70.4734 101.481 71.0417 101.457L81.0417 101.04C81.5771 101.018 82 100.577 82 100.041V80.2297C82 79.5959 81.417 79.1213 80.7961 79.2512Z" fill="#3781FF"/>
            <path d="M60.5 101.049V84.72C60.5 84.4875 60.4219 84.2776 60.2925 84.1096C60.0698 83.8215 59.6951 83.6614 59.304 83.7395L51.304 85.3391C50.8364 85.4329 50.5 85.843 50.5 86.3196V101.449C50.5 102.021 50.9788 102.476 51.55 102.448L59.55 102.048C60.082 102.021 60.5 101.582 60.5 101.049Z" fill="#3781FF"/>
            <path d="M50.5 122.051V136.719C50.5 136.984 50.6038 137.229 50.7769 137.41C50.8389 137.475 50.9099 137.531 50.988 137.578C51.0703 137.628 51.1609 137.665 51.2576 137.69L59.2576 139.69C59.8887 139.847 60.5 139.37 60.5 138.719V122.451C60.5 121.918 60.082 121.48 59.55 121.452L51.55 121.053C50.9788 121.025 50.5 121.48 50.5 122.051Z" fill="#3781FF"/>
            <path d="M81.5 145.806V164.535C81.5 164.954 81.2488 165.296 80.907 165.449C80.6748 165.553 80.4009 165.57 80.1355 165.466L70.6355 161.749C70.2522 161.599 70 161.23 70 160.818V143.741C70 143.103 70.5891 142.628 71.2124 142.764L80.7124 144.829C81.1721 144.929 81.5 145.336 81.5 145.806Z" fill="#3781FF"/>
            <path d="M130.95 121.053L122.95 121.452C122.606 121.47 122.31 121.659 122.143 121.936C122.052 122.086 122 122.263 122 122.451V139.524C122 139.868 122.17 140.16 122.421 140.339C122.684 140.526 123.035 140.586 123.371 140.451L131.371 137.251C131.431 137.228 131.488 137.198 131.541 137.164C131.559 137.153 131.576 137.141 131.594 137.127L131.625 137.104C131.693 137.049 131.754 136.986 131.806 136.915C131.929 136.747 132 136.541 132 136.323V122.051C132 121.48 131.521 121.025 130.95 121.053Z" fill="#3781FF"/>
            <path d="M102.05 101.553L110.05 101.952C110.116 101.955 110.181 101.965 110.242 101.981C110.276 101.99 110.31 101.999 110.342 102.011C110.386 102.028 110.429 102.046 110.47 102.069C110.517 102.094 110.562 102.123 110.605 102.156C110.845 102.338 111 102.627 111 102.951V120.549C111 121.082 110.582 121.521 110.05 121.548L102.05 121.948C101.479 121.976 101 121.521 101 120.949V102.551C101 101.98 101.479 101.525 102.05 101.553Z" fill="#3781FF"/>
            <path d="M101.635 166.251L111.135 162.534C111.792 162.278 112.5 162.761 112.5 163.465V181.417C112.5 181.547 112.475 181.673 112.428 181.789C112.345 181.996 112.193 182.173 111.992 182.287L102.492 187.656C102.332 187.747 102.163 187.788 101.998 187.788C101.828 187.787 101.663 187.742 101.517 187.662C101.216 187.496 101 187.179 101 186.786V167.183C101 166.946 101.083 166.723 101.228 166.547C101.334 166.418 101.473 166.315 101.635 166.251Z" fill="#3781FF"/>
            <path d="M132 68.177V83.8391C132 84.1467 131.862 84.4182 131.648 84.6008C131.437 84.7805 131.152 84.8733 130.852 84.8284L122.852 83.6282C122.362 83.5549 122 83.134 122 82.6389V64.9768C122 64.593 122.21 64.2756 122.508 64.1077C122.675 64.0129 122.869 63.9651 123.07 63.9797L123.125 63.9846L123.166 63.9905C123.235 64.0022 123.303 64.0217 123.371 64.0491L131.371 67.2493C131.751 67.4006 132 67.7688 132 68.177Z" fill="#3781FF"/>
            <path d="M101.713 57.7141L109.713 60.1145C110.048 60.2151 110.384 60.1321 110.628 59.9338C110.853 59.7532 111 59.4768 111 59.1565V41.1184C111 40.9309 110.948 40.7512 110.853 40.5969C110.797 40.5042 110.726 40.4211 110.642 40.3508L110.606 40.3235L110.56 40.2903L110.512 40.26L110.478 40.2405L110.447 40.2239L102.447 36.2239C102.378 36.1897 102.307 36.1643 102.236 36.1467C102.198 36.137 102.159 36.1292 102.12 36.1243C102.082 36.1194 102.043 36.1174 102.005 36.1174C101.479 36.1145 101 36.5374 101 37.1184V56.7561C101 57.1975 101.29 57.5872 101.713 57.7141Z" fill="#3781FF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M93.8477 0.661377C92.4065 -0.220459 90.5935 -0.220459 89.1523 0.661377L39.1523 31.2366C37.8154 32.054 37 33.5081 37 35.0754V54.9319L32.3452 52.0891C30.9053 51.2102 29.0947 51.2102 27.6548 52.0891L2.15479 67.6604C0.816406 68.4778 0 69.9329 0 71.5002V162.94C0 164.508 0.816406 165.963 2.15479 166.781L27.6548 182.351C29.0947 183.231 30.9053 183.231 32.3452 182.351L37 179.509V189.925C37 191.492 37.8154 192.947 39.1523 193.764L89.1523 224.339C90.5935 225.221 92.4065 225.221 93.8477 224.339L143.848 193.764C145.185 192.947 146 191.492 146 189.925V179.498L150.655 182.341C152.095 183.22 153.905 183.22 155.345 182.341L180.845 166.77C182.184 165.952 183 164.497 183 162.93V84.0706C183 82.5032 182.184 81.0481 180.845 80.2307L155.345 64.6594C153.905 63.7805 152.095 63.7805 150.655 64.6594L146 67.5022V35.0754C146 33.5081 145.185 32.054 143.848 31.2366L93.8477 0.661377ZM153 173.228L146 168.953V78.0471L153 73.7727L174 86.5959V160.405L153 173.228ZM37 168.963V65.4768L30 61.2024L9 74.0256V160.415L30 173.238L37 168.963ZM46 187.402V37.5989L90 10.6917V214.309L46 187.402ZM94 213.698L137 187.402V37.5989L94 11.303V213.698Z" fill="#3781FF"/>
        </svg>

    )
}