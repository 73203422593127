import {createCRUDDuck, reducerWithInitialState} from "@sha/fsa";
import * as R from 'ramda'
import {ISOState} from "../../../ISOState";
import settingsDuck from "../settingsDuck";
import type {Schema} from "./ProjectSchema";
import {reject, tail} from "ramda";
import {DateTime} from "luxon";

export type StoneVO = ProjectVO['stones'][number]
export type CommentVO = ProjectVO['comments'][number]
export type ProjectVO = Schema
export type ProjectInfo = {
    projectId: string
    name: string
    modelUrl: string
    stonesUrl: string
    analyticsGroup: string
    analyticsName: string
    projectStatusesType: string
}
const crud = createCRUDDuck<ProjectVO, 'projectId'>('projects', 'projectId')

export type StonePatch= {
    id: string
    statusId: number
}
export type StonesStatusesUpdated = {
    projectId: string
    patches: {
        id: string
        statusId: number
    }[]
}


export type StoneAttrs = {
    K: string
    type:string

    mark: string
    F: string
    level: string
    size: string
}

export type StoneAttrsWithIndex = StoneAttrs & {attrIndex: number, completedAttrs: 0}

const getAttrCode = (attrs: Partial<StoneAttrs>) => (prop: keyof StoneAttrs) => {
    if(attrs[prop]===undefined || attrs[prop].trim() === '')
        return '*'

    return attrs[prop]
}
export const attrsToBaseName = (attrs: Partial<StoneAttrs>) => {
    const codes = getAttrCode(attrs)
    return `K${codes('K')}-${codes('type')}${codes('mark')}-F${codes('F')}-${codes('level')}.${codes('size')}`
}

export const nameWithIndex = (baseName: string) => (index: number) => {
    return baseName+'.'+ String(index + 1).padStart(3,'0')
}
//Mark Mask: K*-**-F*-*.*.001
export const parseStoneAttrs = (stone: StoneVO): StoneAttrsWithIndex => {
    try {
        const hyphens = stone.name.split('-')
        if (hyphens.length < 4) {
            return {
                size: undefined,
                level: undefined,
                mark: undefined,
                type: undefined,
                attrIndex: undefined,
                F: undefined,
                K: undefined,
                completedAttrs: 0,
            }
        }
        const normalize = v => (v === undefined || v.trim().length === 0) ? undefined : v
        const K = normalize(hyphens[0] ? tail(hyphens[0]) : undefined)
        let type, mark

        if (hyphens[1]) {
            const str = hyphens[1]
            type = normalize(str.charAt(0))
            mark = normalize(tail(str))
        }

        let F = normalize(hyphens[2] ? tail(hyphens[2]) : undefined)

        let level, size, attrIndex

        if (hyphens[3]) {
            const dots = hyphens[3].split('.')
            level = normalize(dots[0])
            size = normalize(dots[1])
            attrIndex = normalize(dots[2])
        }

        const result = {
            K: K === '*' ? undefined : K,
            F: F === '*' ? undefined : F,
            type: type === '*' ? undefined : type,
            mark: mark === '*' ? undefined : mark,
            level: level === '*' ? undefined : level,
            size: size === '*' ? undefined : size, attrIndex,
            completedAttrs: 0
        }
        result.completedAttrs = 0
        if (result.K)
            result.completedAttrs++

        if (result.F)
            result.completedAttrs++

        if (result.type)
            result.completedAttrs++

        if (result.mark)
            result.completedAttrs++

        if (result.level)
            result.completedAttrs++

        if (result.size)
            result.completedAttrs++
        return result
    } catch(e) {
        debugger
        return {}
    }
}
// "K" + [Ukon_Корпус] + "-" + [Ukon_Порода камня] + [Ukon_Марка элемента] + "-F" + [Ukon_Фасад] + "-" + [Ukon_Этаж] + "." + [Ukon_Типоразмер]z
const actions = {
    ...crud.actions,

    commentRemoved: crud.factory<{commentId: string, projectId: string}>('commentRemoved', {persistent: true}),
    commentAdded: crud.factory<{ text: string, projectId: string, id: string, commentId: string, userId: string }>('commentAdded', {persistent: true}),
    stoneStatusesUpdated: crud.factory<StonesStatusesUpdated>('stonesStatusesUpdated', {persistent: true}),
    stonesAttributesUpdated: crud.factory<{updates:  {id: string, baseName:string}[], projectId: string}>('stonesRenamed',{persistent: true})
}
const reducer = (state: ProjectVO[], action: any) => {

    let newState = crud.reducer(state, action)
    const projectId = (action.payload ? action.payload.projectId : undefined)
    if (projectId) {
        const index = newState.findIndex(s => s.projectId === projectId)
        const lens = R.lensIndex(index)
        if (index >= 0) {
            const result = R.over(
                lens,
                (obj) =>
                    projectReducer(obj, action)
                ,
                newState
            )
            newState = result
        }
    }
    return newState
}//actions.fetchUsers.asyncReducer

const projectReducer = reducerWithInitialState({} as any as ProjectVO)
    .caseWithAction(actions.stoneStatusesUpdated,
        (state, action) => {
            const stones = [...state.stones]

            action.payload.patches.forEach( p => {
                const oldStone = stones[p.id]
                const newStone: StoneVO = {
                        ...oldStone,
                        id: p.id,
                        statusId: p.statusId,
                        userId: action.userId,
                        updatedAt: action.timestamp,

                    }


                stones[p.id] = newStone
            })

            const res = ({
                ...state,
                stones,
                events: [...(state.events || []), action],
            })

            return res
        }
    )    .caseWithAction(actions.stonesAttributesUpdated,
        (state, action) => {
            const stones = [...state.stones]

            action.payload.updates.forEach( p => {
                const oldStone = stones[p.id]
                const newStone: StoneVO = {
                    ...oldStone,
                    name: p.baseName+'.001'
                }
                stones[p.id] = newStone
            })

            const baseNames: Record<string, number> = {}

            const getIndexForBaseName = (baseName: string): number  => {
                if(!baseNames[baseName])
                    baseNames[baseName] = 1
                else
                    baseNames[baseName]++
                return baseNames[baseName]
            }
            const getBaseName = (s: StoneVO) => {
               const dots =  s.name.split('.')
               if( dots.length >= 3)
                   return R.init(dots).join('.')
                return undefined
            }
            const getStoneName = (s: StoneVO) => {
                const baseName = getBaseName(s)
                if(baseName) {
                    return baseName+'.'+String(getIndexForBaseName(baseName)).padStart(3, '0')
                }
                return s.name
            }
            const indexedStones = stones.map(s => ({
                ...s,
                name: getStoneName(s)
            }))

            const resultStones = indexedStones.map((s,i) =>{
                if(state.stones[i].name === s.name)
                    return state.stones[i]
                return s
            } )
            const res = ({
                ...state,
                stones:resultStones,
                events: [...(state.events || []), action],
            })

            return res
        }
    ).caseWithAction(actions.commentAdded,
        (state, action) => {
            const comments: CommentVO[] = [...state.comments, {
                userId: action.userId,
                createdAt: new Date(action.timestamp),
                updatedAt: new Date(action.timestamp),
                text: action.payload.text,
                commentId: action.payload.commentId,
                id: action.payload.id,
                projectId: action.payload.projectId,
            }]

            return ({
                ...state,
                comments,
                events: [...(state.events || []), action],
            })
        }
    ).caseWithAction(actions.commentRemoved,
        (state, action) => {
            const comments: CommentVO[] = reject(c => c.commentId === action.payload.commentId, state.comments)/*, {
                userId: action.userId,
                createdAt: new Date(action.timestamp),
                updatedAt: new Date(action.timestamp),
                text: action.payload.text,
                commentId: action.payload.commentId,
                id: action.payload.id,
                projectId: action.payload.projectId,
            }]*/
            return ({
                ...state,
                comments,
                events: [...(state.events || []), action],
            })
        }
    )

const selectEventsUpTo = (projectId: string, date: DateTime|string)=>(state: ISOState) => {
    const proj: ProjectVO = crud.selectById(projectId)(state)
    const lastTimestamp = typeof date === 'string' ? date : date.toJSDate().toISOString()
    const list = proj.events.filter( e => e.timestamp <= lastTimestamp  && e.type === projectsCRUD.actions.stoneStatusesUpdated.type)

    return list
}

const projectsCRUD = {
    ...crud,
    actions,
    reducer,
    selectStonesGroupedByStatuses: (projectId: string) => (state: ISOState) => {

        const project: ProjectVO = crud.selectById(projectId)(state)

        const statusBatches = settingsDuck.selectStatuses(state).filter(s => s.statusType === project.projectStatusesType)
            .map(s => ({...s, stones: [] as StoneVO[]}))
        project.stones.forEach(s => {

            statusBatches.find(b=> b.statusId === s.statusId).stones.push(s)

            }
        )
        return statusBatches
    },
    selectStonesByProject: (projectId: string) => (state: ISOState) => {
        return projectsCRUD.selectById(projectId)(state).stones
    },
    selectStone: (projectId: string, stoneId: string) => (state: SEISOState) => {
        return projectsCRUD.selectById(projectId)(state).stones.find(s => s.id == stoneId)
    },
    selectComments: (projectId: string, stoneId: string) => (state: ISOState) => {
        return projectsCRUD.selectById(projectId)(state).comments.filter(s => s.stoneId == stoneId)
    },
    selectEventsUpTo,
    selectHistoryProject: (projectId: string, date: DateTime | string) => (state: ISOState) => {
        const currentProject = crud.selectById(projectId)(state)
        const initialProject: ProjectVO = {
            ...currentProject,
            events: [],
            stones: currentProject.stones.map(s => ({...s, userId: undefined, updatedAt: undefined,statusId: 2}))
        }
        const list = selectEventsUpTo(projectId,date)(state)

        let historyProject = initialProject
        console.log('apply events', list)
        list.forEach(e => {
            e.payload.patches.forEach( p => {
                const oldStone = initialProject.stones[p.id]
                oldStone.userId = e.userId
                oldStone.updatedAt = e.timestamp
                oldStone.statusId = p.statusId


            })
        }
        )
        console.log(list.length+' events applied')


        return historyProject
    },
}

export default projectsCRUD
