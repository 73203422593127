import {IonToolbar} from "@ionic/react";
import {Datepicker} from "@mobiscroll/react";
import React from "react";
export type HistoryDateScrollerProps = {
    min: string
    max: string
    value: string | Date
    onChange: ({value, valueText}) => any
}

export default (props: HistoryDateScrollerProps) => {
    console.log('dateprops', props)
 return <Datepicker
     responsive={{
         xsmall: {
             calendarType:'week',
             calendarSize:1,
         },
         small: {
             calendarType:'week',
             calendarSize:2,
         },
         medium: {
             calendarType: 'month',
             calendarSize: 1,
             touchUi: true
         },

         xlarge: {
             calendarType: 'month',
             calendarSize: 3,

         },
     }}
            value={props.value}
            min={props.min}
            max={props.max}
            onChange={props.onChange}
            dataTimezone={'local'}
            controls={['calendar']}
            touchUi={true}
            display="inline"
     dayNames={false}
        />

}