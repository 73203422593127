import React, {useCallback, useState} from 'react'
import * as Icons from 'ionicons/icons';
import StatusSelector from "../../list-statuses/StatusSelector";
import {nav} from "../nav";
import {
    IonButton,
    IonButtons,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
    IonToolbar, useIonToast, useIonViewDidEnter
} from "@ionic/react";
import {useProject} from "../../../hooks/useProject";
import useStatuses from "../../../hooks/useStatuses";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useStones from "../../../hooks/useStones";
import useViewer from "./tools/useViewer";
import useInteractiveStones from "./tools/useInteractiveStones";
import {equals, head, last, reject} from "ramda";
import HistoryDateScroller from "./tools/HistoryDateScroller";


const ToolHistory = (/*{selectedStoneIds}:{selectedStoneIds: string[]}*/) => {
    const project = useProject()

    const viewer = useViewer()
    const {getStatusById} = useStatuses()
    const {currentRole} = useCurrentUser()
    const onStoneClick = (id) => {
        if(id === undefined)
            return;
        if(selectedStoneIds.includes(id)) {
            setSelectedStoneIds(reject(equals(id), selectedStoneIds))
            return
        }
        if(selectedStoneIds[0] !== id)
            setSelectedStoneIds([id])
    }

    const [lastTimestamp, setLastTimestamp] = useState(new Date())

    const [selectedStoneIds, setSelectedStoneIds, allStones] = useInteractiveStones({onStoneClick,lastTimestamp: lastTimestamp.toISOString()})


    const {updateStatuses} = useStones(selectedStoneIds)
    const [present] = useIonToast();

    const max = last(project.events) ? last(project.events).timestamp : new Date().toISOString()
    const min = head(project.events) ? head(project.events).timestamp : new Date().toISOString()

    const onDateChange = ({value, valueText}) => {
        console.log('valueText', value.toISOString())
        setLastTimestamp(value)
    }

    const stoneId  = selectedStoneIds?selectedStoneIds[0]:undefined
    if(stoneId === undefined) {
        return  <IonFooter translucent={true} >
                     <IonToolbar>

                        <IonItem
                            button

                            lines={'none'}
                        >
                            <IonLabel>Элементы по одному</IonLabel>
                        </IonItem>

                    </IonToolbar>
            <HistoryDateScroller onChange={onDateChange} value={lastTimestamp}  min={min.slice(0,10)} max={max.slice(0,10)} />

        </IonFooter>

    }


    const stone = allStones.find(s => s.id === stoneId)

    if(!stone) {
        return
    }
    const statusId = stone.statusId

    if(!statusId )
        return

    const onStatusChange = nextStatusId => {
        updateStatuses(nextStatusId, selectedStoneIds)
    }

    const onLinkClick = () => {
        window['viewer'] = viewer.viewer
        const toFix = n => n.toFixed(2)
        const controls = {
            px: viewer.viewer.getControls().object.position.x,
            py: viewer.viewer.getControls().object.position.y,
            pz: viewer.viewer.getControls().object.position.z,
            tx: viewer.viewer.getControls().target.x,
            ty: viewer.viewer.getControls().target.y,
            tz: viewer.viewer.getControls().target.z
        }
        Object.keys(controls).forEach( k => controls[k] = toFix(controls[k]))
        const params = {...controls, stoneId}

        const urlParams = new URLSearchParams(params)

        const search = urlParams.toString()
        const link = window.location.origin+window.location.pathname+'?'+search
        console.log('params', params)
        console.log('link', link)
        navigator.clipboard.writeText(link)
        present({message:link, position:'top', duration: 1200})
    }

    const status = getStatusById(statusId)


    if(!status)
        debugger
    const availableStatusIds = status.nextStatusesIds.filter(id => currentRole.destinationStatusesIds.includes(id))

    return <IonFooter translucent={true} >
        <IonToolbar>
            <IonButtons slot="start">
                <IonButton onClick={onLinkClick}>
                    <IonIcon slot="icon-only" size={'large'} icon={Icons.link}/>
                </IonButton>
            </IonButtons>
            <StatusSelector color={'primary'}
                            disabled={true}
                            statusId={statusId} availableStatusIds={[statusId]}
                         >
                <IonLabel slot={'end'}>{stone.name}</IonLabel>
            </StatusSelector>

            <IonButtons slot="primary">
                <IonButton routerLink={nav.stoneCard({projectId: project.projectId, id: stoneId})}>
                    <IonIcon slot="icon-only" size={'large'} icon={Icons.informationCircleOutline}/>
                </IonButton>
            </IonButtons>


        </IonToolbar>
        <HistoryDateScroller onChange={onDateChange} value={lastTimestamp} min={min.slice(0,10)} max={max.slice(0,10)}/>

    </IonFooter>


}

export default React.memo(ToolHistory)
