import React from 'react'
import {IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react"
import moize from 'moize'
import {SelectInterface} from "@ionic/core/dist/types/components/select/select-interface"

export type OnValueChangeHandler<T> = (value: T, name?: string) => any | void

export const defaultOnValueChange = value => undefined

export type WithValueProps<T> = {
    value?: T
    defaultValue?: T
    name?: string
    onValueChange?: OnValueChangeHandler<T>
    onValueMerge?: (value: Partial<T>, name?: string) => any
    id?: string
    disabled?: boolean
}

type WSOption = {
    value?: string
    label: string
    disabled?: boolean
    bgColor?: string
    class?: string

}

type WSSelectProps = WithValueProps<string> & {
    options: WSOption[], slot?: string,
    interfaceOptions?: any,
    labelPosition?: string, multiple?: boolean, placeholder: string, label?: string, type?: SelectInterface
}

const WSSelect = ({
                      value,
                      label,
                      onValueChange,
                      options,
                      disabled,
                      labelPosition,
                      placeholder,
                      multiple,
                      type,
                      interfaceOptions,
                      slot
                  }: WSSelectProps) => {
    const onIonChange = (event) => {

        onValueChange(event.detail.value)
    }
    if (!label)
        return <IonSelect interfaceOptions={interfaceOptions || {}} slot={'end'} disabled={disabled} multiple={multiple}
                          onIonChange={e => onIonChange(e)} interface={type || "popover"} okText={'Выбрать'}
                          cancelText={'Отмена'} value={value} placeholder={placeholder}>
            {
                options.map((o, index) =>
                    <IonSelectOption disabled={o.disabled} class={o.class}
                                     value={o.value || String(index)}>{o.label}</IonSelectOption>
                )
            }
        </IonSelect>
    return (<IonItem>
            {
                label && <IonLabel position={labelPosition || 'floating'}>{label}</IonLabel>
            }
            <IonSelect interfaceOptions={interfaceOptions || {}} disabled={disabled} multiple={multiple}
                       onIonChange={e => onIonChange(e)} interface={type || "popover"} value={value}
                       placeholder={placeholder}>
                {
                    options.map((o, index) =>
                        <IonSelectOption disabled={o.disabled} class={o.class}
                                         value={o.value || String(index)}>{o.label}</IonSelectOption>
                    )
                }
            </IonSelect>
        </IonItem>
    )

}

export default moize(WSSelect)
