import * as FSA from '@sha/fsa';


export const defaultConfig = {}

export type ISOConfig = typeof defaultConfig


const factory = FSA.actionCreatorFactory('config')
const actions = {
    reset: factory<ISOConfig>('reset'),
}

export const configDuck = {
    factory,
    actions,
    selectConfig: (state): ISOConfig =>
        state.app.config,
    selectApiConfig: (state) =>
        state.app.config.mode === 'DEV' ? state.app.config.DEV : state.app.config.PROD,

    reducer: (state = defaultConfig, action): ISOConfig => {
        if (actions.reset.isType(action))
            return action.payload

        return state
    }
}

export default configDuck

