import React, {useRef} from 'react'

import {IonRouterOutlet} from '@ionic/react'
import {IonReactRouter} from "@ionic/react-router";
import {History} from 'history'
import WSContext from '../../contexts/WSContext';
import {rootRoutes} from "./nav";
import {Redirect} from "react-router-dom"
import useUI from "../../hooks/common/useUI"
import WSIonRoute from "./controls/WSIonRoute";


export default ({history}: { history: History }) => {
    const ui = useUI()

    const rootOutletRef = useRef<HTMLIonRouterOutletElement>()
    if (rootOutletRef.current) {
        rootOutletRef.current
    }
    return (
        <WSContext.Provider value={{outletElement: rootOutletRef.current}}>
           <IonReactRouter history={history}>

                {ui.preloaded &&

                    <IonRouterOutlet animated={false} ref={rootOutletRef}>
                        {
                            rootRoutes
                        }
                        <WSIonRoute render={props => {
                            console.log('Redirect', props)
                            return <Redirect to={'/login'}/>
                        }}/>
                    </IonRouterOutlet>
                }

            </IonReactRouter>
        </WSContext.Provider>


    )/*<ConnectedRouter history={historySubscription}>
                    <Switch>
                        {routes}
                    </Switch>
                </ConnectedRouter>*/


}

