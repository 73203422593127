import React, {PointerEventHandler, PointerEvent, useEffect, useRef, useState, useCallback} from 'react'
import useFrontStore from "../../../hooks/common/useFrontStore"

import {Task} from "redux-saga"
import useFrontSelector from "../../../hooks/common/useFrontSelector"
import {usePrevious} from "react-use";
import {useProject} from "../../../hooks/useProject";
import axios from "axios";

import settingsDuck from "iso/src/store/bootstrap/settingsDuck";
import * as THREE from "three";
import {uiDuck} from "../../../store/ducks/uiDuck";
import {useDispatch, useStore} from "react-redux";
import reactViewerSaga from "./reactViewerSaga";
import projectsCURD, {StoneVO} from "iso/src/store/bootstrap/repos/projectsCURD";
import useInteractiveStones from "./tools/useInteractiveStones";
import useViewer from "./tools/useViewer";
import {estimate, sleep} from "@sha/utils";
import {useIonAlert, useIonLoading, useIonViewDidEnter, useIonViewDidLeave} from "@ionic/react";
import useSelectedStoneIds from "../../../hooks/useSelectedStoneIds";
import getRestApi from "iso/src/getRestApi";
import {useHistory} from "react-router";
import {nav} from "../nav";
import useSweepMode from "../../../hooks/common/useSweepMode";
import {move} from "ramda";
import StoneEvent from "./tools/StoneEvent";
import WSViewer from "scene/src";
import {doubles} from "scene/src/k2DirtyHideHook";
import {ViewerOrbitControls} from "scene/src/utils";

const colorsByHex: Map<string, THREE.Color> = new Map<string, THREE.Color>()
const getColorByHex = (hex: string): THREE.Color => {
    if (!colorsByHex.has(hex))
        colorsByHex.set(hex, new THREE.Color(hex))

    return colorsByHex.get(hex)
}

export type ReadyViewer = { viewer: WSViewer, divRoot: HTMLDivElement }


type WSViewerProps = {
    onReady: (rv: ReadyViewer) => any
    onPointerMoved?: () => {}
    selectedStoneIds?: string[]
    onStoneClick?: (stoneId?: string) => any
}

const getDistance = (a: PointerEvent, b: PointerEvent) =>
    Math.sqrt( Math.pow((a.clientX-b.clientX), 2) + Math.pow((a.clientY-b.clientY), 2) );



export default ({onStoneClick, onReady}: WSViewerProps) => {

    const dispatch = useDispatch()
    const divRef = useRef<HTMLDivElement>(null)
    const canvasRef = useRef(null)
    const viewerRef = useRef<WSViewer>(null)
    const taskRef = useRef<Task>(null)
    const settings = useFrontSelector(settingsDuck.selectSettings)
    const project = useProject()
    const store = useStore()
    const history = useHistory()
    const [present, dismiss] = useIonLoading()
    const [presentAlert]  = useIonAlert()

    useEffect(() => {
        console.log('useEffect call, the ref is', divRef.current)
        let canvas
        let viewer: WSViewer
        let task
        const div = divRef.current

        const initialize = async () => {
        present('Загружаем '+project.name)
            const colors = settings.statuses.map(s => {
                const t = getColorByHex(s.color)
                return ({target: s.statusId, r: t.r, g: t.g, b: t.b})
            })
            viewer = new WSViewer(project.modelUrl
            , div, project.projectId === 'k2' ? doubles : []);
            viewerRef.current = viewer
            const safeLoadModel = async () => {
                try {
                    await viewer.loadPromise//project.modelUrl)

                    await sleep(500)
                    return true
                } catch (e: Error) {
                    return e
                }
            }

            const safeLoadData = async () => {
                try {
                    const api = await getRestApi()
                    const data = await api.loadPro(project.projectId)
                    dispatch(projectsCURD.actions.resetOne(data))
                    return true
                } catch (e: Error) {
                    return e
                }
            }
            const [modelRes, dataRes] = await Promise.all([safeLoadModel(),safeLoadData()])
            if(modelRes !== true) {
                console.error('modelRes', modelRes)
                dismiss()
                presentAlert({
                    header: 'Ошибка модели',
                    subHeader: 'Не удалсь загрузить файл '+project.modelUrl,
                    message: modelRes.message,
                    buttons: [
                        {
                            text: 'Другие проекты',
                            role: 'ok',
                            handler: () => {
                                history.replace(nav.access({}))
                            },
                        },
                    ]
                })
                return
            } else if(dataRes!==true) {
                console.error('dataRes', dataRes)
                dismiss()
                presentAlert({
                    header: 'Ошибка данных',
                    subHeader: 'Не удалсь загрузить данные по проекту '+project.projectId,
                    message: dataRes.message,
                    buttons: [
                        {
                            text: 'Другие проекты',
                            role: 'ok',
                            handler: () => {
                                history.replace(nav.access({}))
                            },
                        },
                    ]
                })
                return
            }


            task = taskRef.current = store.runSaga(reactViewerSaga, viewerRef.current)

            const log = (e: PointerEvent) => console.log(e.type, e.clientX,e.clientY)

            dismiss()
        }
        const dispose = () => {

            if (viewer){
                viewer.destroy()
            }
            if (taskRef.current && taskRef.current.isRunning()) {
                const cancelLater = () => {
                    taskRef.current.cancel()
                    taskRef.current = null
                }
                cancelLater()

            }
            console.log('WSReactViewer DISPOSED')
        }
        const promise = initialize().then(() => {

            console.info('WSReactViewer model initialized')
            onReady({viewer,divRoot: div})
        }).catch(e => {
            console.error('WSReactViewer initiazlize error', e)
        })
        divRef.current = div
        canvasRef.current = canvas
        viewerRef.current = viewer

        return () => {}//jdispose
    }, [])


    const [selectedStoneIds] = useSelectedStoneIds()

    const viewerControlsChangeListener = useCallback(() =>{
        const viewerControls = {
            position: {
                x: viewerRef.current.getControls().object.position.x,

                y: viewerRef.current.getControls().object.position.y,

                z: viewerRef.current.getControls().object.position.z,
            },
            target: {
                x: viewerRef.current.getControls().target.x,

                y: viewerRef.current.getControls().target.y,

                z: viewerRef.current.getControls().target.z,
            }
        }
        console.log('viewerControlsListener', viewerControls)
        onViewerControlsChange(viewerControls)
    },[])
    useIonViewDidEnter(() => {
        console.log('WSReactViewer did enter')
        if(selectedStoneIds.length) {
            viewerRef.current.focusToObjects(
                selectedStoneIds.length
                    ? selectedStoneIds.map( i => Number(i))
                    : null
            )
        }
    /*    if(onViewerControlsChange) {
            viewerRef.current.getControls().addEventListener('change',viewerControlsChangeListener)
        }*/
    })

    useIonViewDidLeave(() => {
     /*   if(onViewerControlsChange) {
            viewerRef.current.getControls().removeEventListener('change',viewerControlsChangeListener)
        }*/
    },[])



    const [isDown, setIsDown] = useState(false)
    const [isMove, setIsMoveDispatch] = useState(false)
    const setIsMove = useCallback( (value) => {
        if(divRef && divRef.current)
            divRef.current.style.setProperty('pointer-event', value ? 'auto' : 'node')
        setIsMoveDispatch(value)
    },[divRef])
    const [currentPoint, setCurrentPoint] = useState(undefined)

    const {isSweepMode} = useSweepMode()


    const [downStartEvent, setDownStartEvent] = useState(undefined)

    const getCoords = e => e
        ? Math.ceil(e.clientX)+':'+Math.ceil(e.clientY)
        : undefined
    const log = e => {}//console.log(e.type, getCoords(e), e)

    const down: PointerEventHandler = async e => {
        log(e)
        const stoneId = await viewerRef.current.raycast(e.clientX,  e.clientY)

        if(stoneId === null) {
            setIsMove(true)
        } else {
            setIsMove(false)
        }

        setDownStartEvent(e)
        setIsDown(true)
    }

    const up: PointerEventHandler = async e => {

        log(e)

        const stoneId = await viewerRef.current.raycast(e.clientX,  e.clientY)
        const selectedStoneIds = viewerRef.current.getSelectedElementIds()
        if(!isMove && isDown && stoneId !== null ) {
            if(!isSweepMode || !selectedStoneIds.includes(stoneId)) {
                if (onStoneClick)
                    onStoneClick(String(stoneId))

                window.dispatchEvent(new StoneEvent('activateStone', String(stoneId)))
            }
        }
        setIsDown(false)
        setIsMove(false)
    }

    const move: PointerEventHandler = async e => {
        setCurrentPoint(e)
        if(isDown) {
            if(isSweepMode) {
                const selectedStoneIds = viewerRef.current.getSelectedElementIds()
                const stoneId =estimate( () =>
                    viewerRef.current.raycast(e.clientX,  e.clientY),
                'ray')()

                if(stoneId !== null && !selectedStoneIds.includes(stoneId)) {
                    if(onStoneClick)
                        onStoneClick(String(stoneId))
                    window.dispatchEvent(new StoneEvent('activateStone',String(stoneId)))
                }
            }
            else if (!isMove) {
                const distance = getDistance(downStartEvent, e)
                const limit = (e.height + e.width) / 4 + 10
                console.log(e, 'distance ' + distance + ' vs ' + limit)
                if (distance > limit) {
                    setIsMove(true)
                }
            }
        }
    }

    const enter: PointerEventHandler = e => {
        log(e)
    }




    return (
        <div
             className={'scene'}
             onPointerEnter={enter}
             onPointerDown={down}
             onPointerUp={up}
             onPointerMove={move}
             style={{marginTop:'0px'}}
        >
            {
                /*<div style={{zIndex:20, position: 'relative'}}>
                    <p>params:</p>
                    <p>
                        isSweepMode: {isSweepMode ? 'true' : 'false'}
                    </p>
                    <p>
                        isDown:{isDown ? 'true' : 'false'}
                    </p>
                    <p>
                        start: {getCoords(downStartEvent)}
                    </p>
                    <p>
                        current: {getCoords(currentPoint)}
                    </p>
                    <p>
                        isMove: {isMove ? 'true' : 'false'}
                    </p>
                </div>
             
                 */
            }
            <div className={'scene'} ref={divRef} id={'scene'}>

            </div>
        </div>
    )

}
