import React from 'react'
import * as R from 'ramda'
import './init/initMobiscroll'
//import {registerObserver} from 'react-perf-devtool'

// Simple, no?
//registerObserver()
import {setupIonicReact} from "@ionic/react";

import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';


import './theme/variables.css';
//import './theme/status-colors.scss';
import configureAdminStore from "./store/configureFrontendStore";
import rootFrontSaga from "./sagas/rootFrontSaga";
import './long-press-event'
import {history} from "@sha/router";
import axios from "axios";
import ReactDOM from "react-dom/client";
import App from "./App";
import {projectsCRUD} from "iso/src/store/bootstrap";
import {ducks} from 'iso/src/store'

window['ducks'] = ducks

setupIonicReact({
    rippleEffect: true,
    mode: 'ios',
});

window['axios'] = axios
window['projectsCRUD'] = projectsCRUD


const div = document.getElementById('root') as HTMLDivElement

const store = configureAdminStore()
store.runSaga(rootFrontSaga, store, history)

window['store'] = store
window['R'] = R
document.getElementById('preloader').parentElement.removeChild(document.getElementById('preloader'))

const root = ReactDOM.createRoot(
    document.getElementById("root")
);
root.render(React.createElement(App, {store}));


