import {IonButton, IonButtons, IonHeader, IonIcon, IonMenuToggle, IonToolbar, useIonRouter} from "@ionic/react";
import React from 'react';
import * as Icons from 'ionicons/icons';
import {nav} from "../nav";
import IonCurrentRoute from "../controls/IonCurrentRoute";
import {useProject} from "../../../hooks/useProject";
import {useDispatch} from "react-redux";
import {uiDuck} from "../../../store/ducks/uiDuck";
import {connect} from 'react-redux'
import useUI from "../../../hooks/common/useUI";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import projectsCURD from "iso/src/store/bootstrap/repos/projectsCURD";

const SceneHeader = connect( (state , props: {projectId: string}) => ({
    project: projectsCURD.selectById(props.projectId)(state)
}), dispatch =>  ({dispatch}))( ({projectId,dispatch, project}) => {
    const ionRouter = useIonRouter()

    const onHome = () => {
        dispatch(uiDuck.actions.focusToDefault())
    }

    const ids = useFrontSelector(uiDuck.selectSelection)
    const onFocusSelection = () => {
        if(ids.length)
        dispatch(uiDuck.actions.focusToList(ids))
    }

    const onSearch = () => {
        const projectPath = nav.project({projectId})
        if (ionRouter.routeInfo.pathname === projectPath && ionRouter.routeInfo.search !== '?search=') {
            ionRouter.push(projectPath + '?search')
        }
    }
    return <IonHeader translucent={true}>
        <IonToolbar>

            <IonButtons slot="start">
                <IonMenuToggle>
                    <IonButton>
                        <IonIcon slot="icon-only" icon={Icons.menu}/>
                    </IonButton>
                </IonMenuToggle>
                <IonButton onClick={onHome}>
                    <IonIcon slot="icon-only" icon={Icons.business}/>
                </IonButton>
            </IonButtons>
            <IonCurrentRoute>
                {project.name}
            </IonCurrentRoute>
            <IonButtons slot="end">
                <IonButton onClick={onFocusSelection} disabled={ids.length === 0}>
                    <IonIcon slot="icon-only" icon={Icons.contract}/>
                </IonButton>

                <IonButton id="search-modal" onClick={onSearch}>
                    <IonIcon slot="icon-only" icon={Icons.search}/>
                </IonButton>
            </IonButtons>
        </IonToolbar>
        {/*  <IonToolbar>
            <IonChip outline>

                <IonLabel>Обновлены</IonLabel>
            </IonChip>
            <IonChip outline>

                <IonLabel>Поручены</IonLabel>
            </IonChip>
            <IonChip outline>

                <IonLabel>Комментированы</IonLabel>
            </IonChip>
        </IonToolbar>
        {/*
        <IonToolbar >
            <IonSegment  value="heart">
                <IonSegmentButton value="home">
                    Все камни
                </IonSegmentButton>
                <IonSegmentButton value="heart">
                    Сменить статус
                </IonSegmentButton>
                <IonSegmentButton value="pin">
                    Комментарии
                </IonSegmentButton>

            </IonSegment>
    </IonToolbar>
    */
        }

    </IonHeader>
}
)


export default React.memo(SceneHeader)