import {createCRUDDuck} from "@sha/fsa";
import {GSheetValueRange} from "service/src/rest/settings/getGServices";



const duck = createCRUDDuck<GSheetValueRange,'range'>('sheets','range')

const sheetsDuck = {
    ...duck,
    actions: {
        ...duck.actions,
        editNotified: duck.factory<{timeISO: string, sheets: GSheetValueRange[]}>('editNotified'),
        editUpdated: duck.factory<{timeISO: string, sheets: GSheetValueRange[]}>('editUpdated'),
        editCompleted: duck.factory<any>('editCompleted', {isPublicForAll: true}),
    }
}

export default sheetsDuck