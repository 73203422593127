import React, {useCallback} from 'react';
import useFrontDispatch from "../../../hooks/common/useFrontDispatch";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import projectsCURD, {CommentVO} from "iso/src/store/bootstrap/repos/projectsCURD";
import moment from "moment";
import {usersCRUD, UserVO} from "iso/src/store/bootstrap/repos/usersCRUD";
import {useHistory} from "react-router";
import {nav, useLatestNavParams} from "../nav";
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {uiDuck} from "../../../store/ducks/uiDuck";
import EmptyView from "../controls/EmptyView";
import BackToProjectButton from "../controls/BackToProjectButton";
import {selectCurrentUser} from "../../../store/frontReducer";
import UserAvatar from "../controls/UserAvatar";


const ProjectCommentView = ({onSelect, comment}) => {
    const commentUser: UserVO = useFrontSelector(usersCRUD.selectById(comment.userId))

    const datetime = moment(comment.createdAt).format('YYYY/MM/DD hh:mm')
    const author = <a>{commentUser.firstName} {commentUser.lastName} </a>
    const text = comment.text
    const onClick = useCallback(() => onSelect ? onSelect(comment) : undefined, [comment, onSelect])

    const stone = useFrontSelector(projectsCURD.selectStone(comment.projectId, comment.id))
    const itemProps = {
        button: !!onSelect,
        onClick: onSelect ? onClick : undefined
    }


    return <IonItem {...itemProps}>
        <UserAvatar userId={commentUser.userId}/>
        <IonLabel style={{whiteSpace: 'break-spaces'}}>
            <h3>{author}</h3>
            <p>{datetime}</p>
            <h5>{comment.text}</h5>
        </IonLabel>
        <IonText
            slot="end"
            style={{maxWidth: '100px', overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
        >
            {stone.name}
        </IonText>
    </IonItem>
}

const ProjectCommentsScreen: React.FC = () => {
    const dispatch = useFrontDispatch()
    const history = useHistory()
    const {projectId} = useLatestNavParams(nav.project)
    const project = useFrontSelector(projectsCURD.selectById(projectId))

    const comments = project ? project.comments : []

    const onItemSelect = async (c: CommentVO) => {
        history.replace(nav.project({projectId}), {direction: 'back'})
dispatch(uiDuck.actions.setSelection([c.id]))
        setTimeout(()=>
        dispatch(uiDuck.actions.focusToList([c.id])), 200)
        return true
    }
    return (<IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <BackToProjectButton/>
                </IonButtons>
                <IonTitle>Комментарии проекта</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                {
                    comments.length
                        ? comments.map(c =>
                            <ProjectCommentView comment={c} key={c.commentId} onSelect={onItemSelect}/>
                        )
                        : <EmptyView description={'Нет комментариев'}/>
                }
            </IonList>
        </IonContent>
    </IonPage>)
}

export default ProjectCommentsScreen;
