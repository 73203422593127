import {call, delay, fork, put, select, take, takeLatest} from 'typed-redux-saga'


import {metaDuck} from 'iso/src/store/metaDuck'
import {adminNotifySaga} from './adminOverlayNotifySaga'
import * as router from '@sha/router'
import {takeLatestRoute} from '@sha/router'
import {nav} from '../components/screens/nav'
import {generateGuid} from "@sha/random/src"
import {uiDuck} from '../store/ducks/uiDuck'
import sseClientSaga from "iso/src/store/sse/sseClientSaga"
import getRestApi from "iso/src/getRestApi"
import {History} from 'history'
import {sleep} from "@sha/utils"
import {FrontState, selectCurrentUser} from '../store/frontReducer'
import {Bootstrap, bootstrapDuck} from "iso/src/store/bootstrapDuck";
import projectsCURD, {ProjectVO} from "iso/src/store/bootstrap/repos/projectsCURD";
import {UserVO} from "iso/src/store/bootstrap/repos/usersCRUD"
import {connectionDuck} from "iso/src/store"
import {SSEClientFront} from "iso/src/store/sse/SSEClientFront"
import {usersCRUD} from "iso/src/store/bootstrap"
import {appStorage} from "iso"
import settingsDuck from "iso/src/store/bootstrap/settingsDuck"
import {routerDuck} from "../store/ducks/routerDuck"
import {Preferences, preferencesDuck} from '../store/ducks/preferencesDuck'

const getURLCreds = () => {
    const params = Object.fromEntries(new URLSearchParams(window.location.search))
    if(params.password && params.email)
        return {
            password: params.password,
            email: params.email,
            remember: true,
            pathname: window.location.pathname
        }

    return undefined
}
export function* loginSaga(history: History) {

    const api = yield* call(getRestApi)
    window['restApi'] = api

    while (true) {
        const pathname = history.location.pathname

        const credentials =getURLCreds() || appStorage.getItem('credentials')

        if (!pathname || pathname == '/') {
            yield* call(sleep, 200)
        }
        let path = window.location.pathname

        if (path.endsWith('/login') || path.length < 2) {
            yield* put(uiDuck.actions.preloaded(undefined))
            yield* put(routerDuck.actions.replacePage({url: nav.login()}))
            appStorage.removeItem('credentials')
        } else if (credentials) {
            yield* fork(function* autoLogin() {
                yield* call(delay, 100)
                yield* put(uiDuck.actions.loginRequested({...credentials}))
            })
        } else {
            yield* put(router.replace(nav.login)())
            yield* put(uiDuck.actions.preloaded(undefined))
            appStorage.removeItem('credentials')
        }

        let result = {bootstrap: {} as any as Bootstrap}
        while (true) {
            try {
                const loginAction = yield* take(uiDuck.actions.loginRequested.isType)
                const {email, password} = loginAction.payload
                result = yield* call(api.fetchBootstrap, {email, password})
                yield* put(uiDuck.actions.loggedIn({email, password}))

                yield* put(bootstrapDuck.actions.fetchBootstrap.done(result))

                yield* put(settingsDuck.actions.reset(result.bootstrap.settings))


                yield* put(projectsCURD.actions.reset(result.bootstrap.projects))

                yield* put(usersCRUD.actions.reset(result.bootstrap.users))

                const currentUser = result.bootstrap.users.find(u => u.email === email)

                yield* put(uiDuck.actions.setLogin(email))
                const preferences: Preferences = yield* select(preferencesDuck.selectPreferences)
                const preferenceRoleId = preferences.roleId
                const uiRole = currentUser.roleIds.includes(preferenceRoleId) ? preferenceRoleId : currentUser.roleIds[0]
                yield* put(uiDuck.actions.setRole(uiRole))
                console.log('login result', result)

                break
            } catch (e) {
                appStorage.removeItem('credentials')
                yield* put(uiDuck.actions.unbusy('Login'))
                yield* put(uiDuck.actions.preloaded(undefined))


                if (!window.location.pathname.startsWith('/app/login'))
                    yield* put(router.replace(nav.login)())
            }
        }
        const pathnameP = history.location.pathname
        if(pathnameP.startsWith('/app/in/projects/')) {
            const projId = pathnameP.split('/').pop()
            if (result.bootstrap.projects.find(p => p.projectId === projId) === undefined) {
                yield* put(router.replace(nav.access)({}))
                yield* delay(50)
            }
        }

        ///  yield* fork(bootstrapSetupSaga)

        yield* put(uiDuck.actions.busy('fetchAdminState'))
        yield* put(uiDuck.actions.unbusy('Login'))
        yield* fork(adminNotifySaga)
        const user: UserVO = yield* select(selectCurrentUser)
        const roleId = yield* select(uiDuck.selectRole)
        if(!roleId)
            yield* put(uiDuck.actions.setRole(user.roleIds[0]))

        yield* takeLatest(uiDuck.actions.setRole.isType, function* (action) {
            yield* put(preferencesDuck.actions.setKey({key:'roleId', value: action.payload}))
        })
        yield* fork(sseClientSaga)
        const meta = {userId: user.userId, storeGuid: 'S' + generateGuid()}
        yield* put(connectionDuck.actions.gatewayChanged(SSEClientFront.getSSERoute(meta)))
        yield* put(metaDuck.actions.metaUpdated(meta))
        yield* put(uiDuck.actions.unbusy('fetchAdminState'))


        //const ypState =  mockState

        //  const boot = yield* call(api.fetchBootstrap)//


        if (window.location.pathname === '/app/login')
            yield* put(router.push(nav.access)({}))

        // yield* delay(600)
        //yield* put(configDuck.actions.ozonRefetchIntervalChanged(1))
        yield* put(uiDuck.actions.preloaded(undefined))
        yield* put(uiDuck.actions.unbusy('Login'))
        const action = yield* take(uiDuck.actions.logout.isType)


        yield* call(sleep, 100)
        yield* put(uiDuck.actions.loggedOut(undefined))
        yield* put(uiDuck.actions.setLogin(''))
        appStorage.removeItem('credentials')

        //yield* put(usersCRUD.actions.reset([]))
        // yield* put(projectsCURD.actions.reset([]))


    }
    // yield* call(sceneSaga_ex, document.getElementById('scene'),'/models/model.wsmdl')


}



