import useFrontSelector from "./common/useFrontSelector";
import {selectCurrentUser} from "../store/frontReducer";
import useRole from "./useRole";
import {UserVO} from "iso/src/store/bootstrap/repos/usersCRUD";
import {RoleVO, settingsDuck} from "iso/src/store/bootstrap/settingsDuck";

export default () => {

    const currentUser: UserVO = useFrontSelector(selectCurrentUser)
    const {currentRole, isAdmin} = useRole()
    const allRoles: RoleVO[] = useFrontSelector(settingsDuck.selectSettings).roles
    const availableRoles = allRoles.filter(r => currentUser.roleIds.includes(r.roleId))
    return {
        currentRole,
        currentUser,
        availableRoles,
        isAdmin,

    }
}
