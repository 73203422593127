import React, {useRef, useState} from 'react'
import {
    IonBadge,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonThumbnail,
    IonTitle,
    IonToolbar,
    useIonModal
} from '@ionic/react';
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import {selectCurrentUser} from "../../../store/frontReducer";
import * as Icons from 'ionicons/icons'
import {businessOutline, calendarOutline, chatbubblesOutline, pulseOutline} from 'ionicons/icons'
import frontConfigDuck from "../../../store/ducks/frontConfigDuck"
import ExitButton from "../controls/ExitButton"
import {useHistory, useLocation, useRouteMatch} from "react-router"
import {nav} from "../nav"
import {useProject} from "../../../hooks/useProject"
import {UserVO} from "iso/src/store/bootstrap/repos/usersCRUD"
import useUI from "../../../hooks/common/useUI"
import usePreferences from "../../../hooks/common/usePreferences"
import useRole from "../../../hooks/useRole"
import projectsCURD from "iso/src/store/bootstrap/repos/projectsCURD"
import Logo from '../../../theme/Logo'

export default ({projectId}) => {

    const project = useProject(projectId)

    const menu = useRef<HTMLIonMenuElement>()
    const user: UserVO = useFrontSelector(selectCurrentUser)
    const ui = useUI()


    const history = useHistory()

    const frontConfig = useFrontSelector(frontConfigDuck.selectFrontConfig)

    const location = useLocation()
    location.state


    const {currentRole} = useRole()
    const dates = frontConfig.LASTCOMMITDATETIME.split('_')
    const times = dates[1].split('')
    const dateString = dates[0].split('-').join('/') + ' ' + times[0] + times[1] + ':' + times[2] + times[3]

    const {preferences} = usePreferences()

    const reportDownloadLink =  '/api/v1/report/'+projectId+''

    return <IonMenu side="start" type={'overlay'} ref={menu} contentId="main" id={'menu'}>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <ExitButton onExit={() => menu.current ? menu.current.close() : undefined}/>
                </IonButtons>
                <IonTitle>{user ? user.email : ''}</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent>
            <IonList>
                <IonMenuToggle key={'/app/in/projects'} auto-hide="false">
                    <IonItem button routerLink={'/app/in/projects'} routerDirection={'back'}>
                        <IonIcon icon={businessOutline} slot="start"/>
                        <IonLabel>
                            {project.name}
                            <p>{currentRole.name}</p>
                        </IonLabel>
                    </IonItem>
                </IonMenuToggle>
                <IonMenuToggle key={nav.comments.pattern} auto-hide="false">
                    <IonItem button routerLink={nav.comments({projectId})}>
                        <IonIcon icon={chatbubblesOutline} slot="start"/>
                        <IonLabel>
                            Комментарии
                        </IonLabel>
                        <IonBadge slot={'end'}>{project.comments.length}</IonBadge>
                    </IonItem>
                </IonMenuToggle>
                <IonMenuToggle key={nav.agenda.pattern} auto-hide="false">
                    <IonItem button routerLink={nav.agenda({projectId})}>
                        <IonIcon icon={Icons.listCircle} slot="start"/>
                        <IonLabel>
                            Сводка
                        </IonLabel>
                        <IonBadge color="medium" slot={'end'}>{project.events.filter(e => projectsCURD.actions.stoneStatusesUpdated.isType(e)).length}</IonBadge>
                    </IonItem>
                </IonMenuToggle>
                <IonMenuToggle key={nav.stats.pattern} auto-hide="false">
                    <IonItem button routerLink={nav.stats({projectId})}>
                        <IonIcon icon={Icons.readerOutline} slot="start"/>
                        <IonLabel>
                            Итоги
                        </IonLabel>
                        <IonBadge color="medium" slot={'end'}>{project.stones.length}</IonBadge>
                    </IonItem>
                </IonMenuToggle>
                <IonItem >
                    <a href={reportDownloadLink} download={true}>
                        <IonLabel>
                            Отчёт xlsx
                        </IonLabel>

                    </a>
                </IonItem>

                <IonMenuToggle key={'chart'} auto-hide="false">
                    <IonItem disabled>
                        <IonIcon icon={pulseOutline} slot="start"/>
                        <IonLabel>
                            График
                        </IonLabel>
                        <IonBadge color="primary" slot={'end'}>{project.stones.length}</IonBadge>
                    </IonItem>
                </IonMenuToggle>
                <IonMenuToggle key={'events'} auto-hide="false">
                    <IonItem disabled>
                        <IonIcon icon={calendarOutline} slot="start"/>
                        <IonLabel>
                            История изменений
                        </IonLabel>
                        <IonBadge color="primary" slot={'end'}>{project.events.length}</IonBadge>
                    </IonItem>
                </IonMenuToggle>

                <IonItemDivider></IonItemDivider>
                <IonMenuToggle key={'settings'} auto-hide="false">
                    <IonItem routerLink={nav.settings({projectId})}>
                        <IonIcon icon={Icons.settings} slot={'start'}/>
                        <IonLabel>Настройки</IonLabel>
                    </IonItem>
                </IonMenuToggle>
                <IonMenuToggle key={'help'} auto-hide="false">
                    <IonItem routerLink={nav.tutorial({projectId})}>
                        <IonIcon icon={Icons.help} slot="start"/>
                        <IonLabel>
                            О программе
                        </IonLabel>
                    </IonItem>
                </IonMenuToggle>
                {
                    preferences.showExamples &&
                    <>
                        <IonItemDivider>Эксперименты </IonItemDivider>
                        {Object.values(nav).filter(o => o.pattern.includes('examples')).map(o =>
                            <IonItem routerLink={o({projectId})}>
                                <IonLabel>
                                    {o.pattern.split('/').pop()}
                                </IonLabel>
                            </IonItem>
                        )}
                    </>
                }
            </IonList>
        </IonContent><IonFooter>
        <IonToolbar>
            <IonItem lines={'none'}>
                <IonThumbnail slot="start">
                    <Logo style={{width:'100%', height: '100%'}}/>
                </IonThumbnail>
                <IonLabel>
                    <h3>СтройМониторинг</h3>
                    <p>{frontConfig.VERSION.split('-')[0]} @ {dateString}</p>
                </IonLabel>
            </IonItem></IonToolbar>
    </IonFooter>
    </IonMenu>


};
