import React, {useCallback, useEffect, useState} from 'react'
import * as Icons from 'ionicons/icons';
import StatusSelector from "../../list-statuses/StatusSelector";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import {useDispatch} from "react-redux";
import {uiDuck} from "../../../store/ducks/uiDuck";
import {nav} from "../nav";
import {useHistory} from "react-router";
import {
    IonButton,
    IonButtons,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonToolbar,
    useIonViewDidEnter
} from "@ionic/react";
import {useProject} from "../../../hooks/useProject";
import useStatuses from "../../../hooks/useStatuses";
import useCurrentUser from "../../../hooks/useCurrentUser";
import projectsCURD, {StonePatch} from "iso/src/store/bootstrap/repos/projectsCURD";
import useStones from "../../../hooks/useStones";
import useViewer from "./tools/useViewer";
import useInteractiveStones from "./tools/useInteractiveStones";
import {equals, reject} from "ramda";
import useSelectedStoneIds from "../../../hooks/useSelectedStoneIds"
import useRole from "../../../hooks/useRole"
import useUser from "../../../hooks/useUser"
import ElementsFilterSelector from "./ElementsFilterSelector"
const ToolMultiple = (/*{selectedStoneIds}:{selectedStoneIds: string[]}*/) => {
    const {viewer} = useViewer()
    const dispatch = useDispatch()

    const project = useProject()
    const stones = project.stones
    const {currentRole,isAdmin,currentUser} = useCurrentUser()
   /* useEffect(() => {
        if(!viewer)
            return () =>{}
        if(!isAdmin) {
            viewer.setDisabledElementIds(stones.filter(s => {
                // const list =   allowedStatusesByStoneId(s.id)

                const result = currentRole.sourceStatusesIds.includes(s.statusId)

                //console.log(s.id, s.name, s.statusId, result)
                return !result//.length === 0
                }
            ).map( s => Number(s.id)))
        }

        ()=>{
            viewer.setDisabledElementIds([])
        }
    }, [stones, currentRole, viewer])

    */
  ///  console.log('ToolSingle', viewer)

    const {projectId} = project

    const {getStatusById} = useStatuses()


    const getStone = stoneId =>
        project.stones[stoneId]

    const [selectedStoneIds2] = useSelectedStoneIds()

    const onStoneClick = (id) => {
        const selectedStoneIds = window.store.getState().ui.selection.ids
        if(id === undefined)
            return;
      ///  console.log('ToolMultiple:onStoneClick '+id+'\rcurrentSelection'+selectedStoneIds.join(','))
        if (selectedStoneIds.includes(id)) {
            setSelectedStoneIds(reject(equals(id), selectedStoneIds))
            return
        }
        if(selectedStoneIds.length>0) {
            const firstStone = getStone(selectedStoneIds[0])
            const newStone = getStone(id)
            console.log('First stone status '+firstStone.statusId+', second status '+newStone.statusId)
            if(firstStone.statusId !==newStone.statusId) {
                console.log('Вы не можете выбирать группой элементы с различными статусами')
                return;
            }

        }

        const resultSelectedStoneIds=[id, ...selectedStoneIds]
    ///    console.log('ToolMultiple:onStoneClick COMPELTED resultSelectedIds'+resultSelectedStoneIds.join(','))

        setSelectedStoneIds(resultSelectedStoneIds)


    }

    const [selectedStoneIds, setSelectedStoneIds] = useInteractiveStones({onStoneClick})
    const stoneId  = selectedStoneIds[0]
    if(!stoneId) {

            return <IonFooter>
                <IonToolbar>
                    <IonItem
                        button
                        lines={'none'}
                    >
                        <IonLabel>Группа элементов по статусу</IonLabel>
                    </IonItem>
                </IonToolbar>
            </IonFooter>

        }


        const stone = project.stones.find(s => s.id === stoneId)


    const statusId = stone.statusId




    const onStatusChange = nextStatusId => {
        const patches: StonePatch[] = selectedStoneIds.map( id =>({
            id,
            statusId: nextStatusId,
        }))

        const action = projectsCURD.actions.stoneStatusesUpdated({
            patches,
            projectId,
        })
        action.meta.persistent = true
        dispatch(action)
        //setTimeout(setSelectedStoneIds,100,[])
    }

    const status = getStatusById(statusId)


    if(!status)
        debugger
    const availableStatusIds = status.nextStatusesIds.filter(id => currentRole.destinationStatusesIds.includes(id))

    return <IonFooter  translucent={true}>
        {
            //<IonToolbar><ElementsFilterSelector/></IonToolbar>
        }
        <IonToolbar>

            <StatusSelector color={'primary'}
                            statusId={statusId} availableStatusIds={availableStatusIds}
                            onStatusChange={onStatusChange}>
                <IonLabel slot={'end'}>{stone.name}</IonLabel>
            </StatusSelector>

            <IonButtons slot="primary">
                <IonButton routerLink={nav.stoneCard({projectId: project.projectId, id: stoneId})}>
                    <IonIcon slot="icon-only" size={'large'} icon={Icons.informationCircleOutline}/>
                </IonButton>
            </IonButtons>


        </IonToolbar>
    </IonFooter>


}

export default React.memo(ToolMultiple)
