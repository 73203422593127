import React, {useRef, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonSpinner,
    IonTextarea,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './CommentAddBox.css';
import * as Icons from 'ionicons/icons'
import {OverlayEventDetail} from "@ionic/core/components";
import {useProject} from "../../../hooks/useProject";
import {useHistory} from "react-router";
import useFrontDispatch from "../../../hooks/common/useFrontDispatch";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import {selectCurrentUser} from "../../../store/frontReducer";
import projectsCURD from "iso/src/store/bootstrap/repos/projectsCURD";
import {generateGuid} from "@sha/random";
import useModalRoute from "../../../hooks/useModalRoute";
import {nav} from "../nav";


type MessageBoxProps = {
    onPost: (text: string) => Promise<any>
    disabled?: boolean
    stoneId?: string
}


const CommentAddBox: React.FC<MessageBoxProps> = ({stoneId}: MessageBoxProps) => {

    const maxLength = 300
    const project = useProject()
    const {projectId} = project
    const id = stoneId
    const stone = project.stones.find(s => s.id === stoneId)
    const [isSending, setSending] = useState(false)
    const [text, setText] = useState('' as any as string)

    const modal = useRef<HTMLIonModalElement>(null);
    const input = useRef<HTMLIonTextareaElement>(null);


    const history = useHistory()
    const dispatch = useFrontDispatch()
    const user = useFrontSelector(selectCurrentUser)

    const onPostComment = async (text) => {
        const action = projectsCURD.actions.commentAdded({
            text, id, projectId, commentId: generateGuid(),
            userId: user.userId
        })
        dispatch(action)
    }
    const confirm = async () => {
        setSending(true)
        await onPostComment(text)
        modal.current?.dismiss(input.current?.value, 'confirm');
        history.goBack()
    }

    const [isOpen, onWillDismiss, onWillPresent] = useModalRoute(nav.stoneCard({projectId, id: stoneId}) + '?add')

    function onDidDismiss(ev: CustomEvent<OverlayEventDetail>) {
        setText('')
        setSending(false)
    }

    return (
        <IonToolbar>
            <IonButton id={'add-comment-modal'} expand={'full'}>Добавить комментарий</IonButton>
            <IonModal ref={modal} onDidDismiss={onDidDismiss} trigger="add-comment-modal" onWillPresent={onWillPresent}
                      isOpen={isOpen} onWillDismiss={(ev) => onWillDismiss(ev)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => modal.current?.dismiss()}>

                                <IonIcon icon={Icons.closeCircleOutline} color='danger'></IonIcon>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>Элемент {stone.name}</IonTitle>
                        <IonButtons slot="end">
                            {isSending
                                ? <IonSpinner name="crescent"/>
                                : <IonButton

                                    strong={true}
                                    onClick={() => confirm()}
                                    disabled={!text || text.trim().length === 0}
                                > <IonIcon
                                    icon={Icons.send}

                                >

                                </IonIcon>

                                </IonButton>
                            }

                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <form>
                        <IonItem>
                            <IonLabel position="stacked">Ваш
                                комментарий <b>{text.length}/{maxLength}</b> символов</IonLabel>
                            <IonTextarea value={text} onIonInput={e => {

                                setText(e.detail.value)
                            }} autofocus={true}
                                         maxlength={maxLength} ref={input} autoGrow={true} placeholder="Введите текст"/>
                        </IonItem>
                    </form>
                </IonContent>
            </IonModal>

        </IonToolbar>

    );
};

export default CommentAddBox;
