
import {cancelled, put, select, takeLatest} from "typed-redux-saga";

import * as THREE from "three";
import {uiDuck} from "../../../store/ducks/uiDuck";
import WSViewer from "scene/src";


export default function* reactViewerSaga(viewer: WSViewer
) {
    try {
            console.log('SCENE SAGA STARED')
            const ui = yield* select(uiDuck.selectUI)
            const urlParams =new URLSearchParams( window.location.search)
            if(urlParams.has('stoneId')) {
                yield* put(uiDuck.actions.setSelection([urlParams.get('stoneId')]))
            }

            const coords = Object.fromEntries(urlParams.entries())
            const o = viewer.getControls()
            const t = o.target
            const p = o.object.position
            t.set(Number(coords.tx || t.x), Number(coords.ty || t.y),Number(coords.tz || t.z))
            p.set(Number(coords.px||p.x), Number(coords.py||p.y),Number(coords.pz||p.z))



        yield* takeLatest(uiDuck.actions.focusToDefault.isType, function* (action) {
            if (viewer)
                viewer.focusToObjects(null)
        })

        yield* takeLatest(uiDuck.actions.focusToList.isType, function* (action) {
            if (viewer)
                viewer.focusToObjects(action.payload.map(p => Number(p)))
        })
        yield* takeLatest(uiDuck.actions.searchResultSelected.isType, function* (action) {
            const ids = action.payload.ids
            yield* put(uiDuck.actions.setSelection(ids))
            viewer.focusToObjects(ids.map(id => Number(id)))
        })
    } catch (e) {
        if (yield* cancelled()) {
            console.log('Viewer saga cancellled succesfully')
        } else {
            debugger

            console.error('Viewer saga crashed', e)
        }
    }


}
