import {WSStartViewer} from "./WSStartViewer";
import {WSAttachedViewer} from "./WSAttachedViewer";
import {WSOffScreenViewer} from "./offscreen/WSOffScreenViewer";
import {WSStartMeshViewer} from "./WSStartMeshViewer";

export class WSViewer extends WSStartViewer  {
    constructor(url, root, idsToSkip) {
        super(url, root,idsToSkip);
    }
}
export default WSViewer