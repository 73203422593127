import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import chroma from "chroma-js"
import {estimate} from "@sha/utils";
import {
    ViewerOrbitControls,
    defaultColorHex,
    defaultMaterial,
    getColorAnimTint,
    getColorByHex, getDisabledTint, HEX,
    WSAnimation,
    WSElementID,
    WSViewerRenderable
} from "./utils";
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import {needToSkip} from "./k2DirtyHideHook";

const logColor = (bg,cl) => console.log('%c'+bg+' over '+cl, "background:"+bg+";color:"+cl+";font-size:2em;")

// Add the extension functions
//THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
//THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
//THREE.Mesh.prototype.raycast = acceleratedRaycast;
//THREE.InstancedMesh.prototype.raycast = acceleratedRaycast;
// Generate geometry and associated BVH

window['THREE'] = THREE
const tmpBBox = new THREE.Box3()

export class WSStartViewer {
    readonly ndc: THREE.Vector2
    readonly scene: THREE.Scene
    readonly camera: THREE.PerspectiveCamera
    readonly colors: Array<THREE.Color>
    readonly renderer: THREE.WebGLRenderer;
    public readonly controls: OrbitControls;
    readonly elements: Map<WSElementID, Array<WSViewerRenderable>>;
    readonly modelBBox: THREE.Box3;
    readonly focusTime: number;
    readonly raycaster: THREE.Raycaster;
    readonly renderables: Map<string, WSElementID>;
    readonly animation: WSAnimation;
    public loaded: boolean = false

    selectedIds: WSElementID[] = [];
    hoveredIds: WSElementID[] = [];
    colorsHEXByElementIds: HEX[] = [];
    colorsByHex: Map<HEX, THREE.Color> =   new Map<HEX, THREE.Color>();
    pr: number = window.devicePixelRatio;
    raf: number = 0;
    time: number = 0;
    width: number = 1;
    height: number = 1;
    isResized: boolean = true;
    isModelUpdated: boolean = false;
    isCameraUpdated: boolean = false;

    public animatedColors = true;
    public fadeDisabledElements = true;
    constructor(public url: string, public root: HTMLElement,public idsToSkip: string[] = []) {

        const canvas = document.createElement('canvas')

        this.ndc = new THREE.Vector2();
        this.root = root;
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, 1, 0.1, 10000);
        this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas });

        this.elements = new Map();
        this.modelBBox = new THREE.Box3();
        this.focusTime = 0.3;
        this.raycaster = new THREE.Raycaster();
        this.raycaster.firstHitOnly = true;
        this.renderables = new Map();

        this.animation = {
            enable: false,
            startTime: 0,
            start: { polar: 0, distance: 0, azimuthal: 0, target: new THREE.Vector3() },
            end: { polar: 0, distance: 0, azimuthal: 0, target: new THREE.Vector3() }
        };

        this.camera.position.z = 50;

        // const canvas = this.renderer.domElement;
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        root.appendChild(canvas);
        this.controls = new OrbitControls(this.camera, root);

        this.controls.addEventListener('change', ( e ) => {
            //debugger
            console.log('controls changed',e)

            this.isCameraUpdated = true;
        });
        this.controls.addEventListener('start', e => {
          //  console.log('start', e)
            this.controls.saveState();
        })

        this.renderer.setClearColor('#000', 0);
        //, 'RAF estimate');
        this.loaded=true
        this.loadPromise = this.load(this.url).then(this.loadedHandler)
    }

    public disabledElementIds = [] as WSElementID[]
    public prevDisabledElementIdsMap = {}

    public setDisabledElementIds = (ids: WSElementID[]) => {
        const nextDisabledIdsMap = {}

        ids.forEach(id => nextDisabledIdsMap[id] = true)
        const addedToEnabledElements = []//this.disabledElementIds.filter(id => !newDisabledIdsMap[id])
        this.disabledElementIds.forEach(id => {
            if(!nextDisabledIdsMap[id]) {
                addedToEnabledElements.push(id)
                this.setElementColor(id, this.colorsHEXByElementIds[id])
            }
        })

        const addedToDisabledElements = []//this.disabledElementIds.filter(id => !newDisabledIdsMap[id])
        ids.forEach(id => {
            if(!this.prevDisabledElementIdsMap[id]) {
                addedToDisabledElements.push(id)
                const tintedHex = getDisabledTint(this.colorsHEXByElementIds[id])
               // logColor(this.colorsHEXByElementIds[id], tintedHex)
                this.setElementColor(id, '#f3f3f0')//tintedHex)

            }
        })

        this.prevDisabledElementIdsMap = nextDisabledIdsMap
        this.disabledElementIds = ids;
        this.isModelUpdated = true;
    }


    public getViewerOrbitControls = (): ViewerOrbitControls => {
        return {
            position: {
                x: this.controls.target0.x,
                y: this.controls.target0.y,
                z: this.controls.target0.z,
            },
            target: {
                x: this.controls.target.x,
                y: this.controls.target.y,
                z: this.controls.target.z,
            }
        }
    }
    public setViewerOrbitControls = (cam:ViewerOrbitControls) => {
    if(this.controls.target0.x !== cam.position.x ||
        this.controls.target0.y !== cam.position.y ||
        this.controls.target0.z !== cam.position.z ||
        this.controls.target.x !== cam.target.x ||
        this.controls.target.y !== cam.target.y ||
        this.controls.target.z !== cam.target.z

    ) {
        this.controls.target0.set(cam.position.x, cam.position.y, cam.position.z)
        this.controls.target.set(cam.target.x, cam.target.y, cam.target.z)
        this.controls.update();
        this.isCameraUpdated = true;
    }
    }
    public loadedHandler = () => {   //f estimate(
       const refCallback = (time: number): void => {
            this.time = time * 0.001;

            this.updateSize();
            this.controls.update();

            this.animate();
            this.animateSelection();

            if (this.isResized || this.isCameraUpdated || this.isModelUpdated) {
                this.isResized = false;
                this.isModelUpdated = false;
                this.isCameraUpdated = false
//this.camera.position.set(this.camera.position.x+=0.1, this.camera.position.y, this.camera.position.z+=1)
  //              console.log(this.camera.position)
                //estimate(() =>
                    this.renderer.render(this.scene, this.camera)
                       /// , 'render time')()
            }

            this.raf = requestAnimationFrame(refCallback);
        }

        this.raf = requestAnimationFrame(refCallback);
    }
    public loadPromise: Promise<any>

    public getControls = () =>
        this.controls

    async load(url: string): Promise<void> {
        this.clear();

        const response = await fetch(url);
        if (response.ok === false) {
            throw new Error(`WSMDL file ${url} not found.`);
        }

        const buffer = await response.arrayBuffer();
        this.loadArrayBuffer(buffer);
    }


    public loadArrayBuffer (buffer: ArrayBuffer): void {
        const u32 = new Uint32Array(buffer);
        const f32 = new Float32Array(buffer);
        const batches: Array<{ index: number; mesh: THREE.InstancedMesh }> = [];
        let offset: number = 3;

        if (u32[0] !== 0x004D5357) { throw new Error('Inkorrect wsmdl file.'); }

        for (let i = 0; i < u32[1]; i++) {
            const elementsCount = u32[offset + 0];
            const verticesCount = u32[offset + 1];
            const indicesCount = u32[offset + 2];
            offset += 3;

            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute(
                'position',
                new THREE.BufferAttribute(new Float32Array(buffer, offset * 4, verticesCount * 3), 3)
            );
            offset += verticesCount * 3;
            geometry.setAttribute(
                'normal',
                new THREE.BufferAttribute(new Float32Array(buffer, offset * 4, verticesCount * 3), 3)
            );
            offset += verticesCount * 3;
            geometry.setIndex(
                new THREE.BufferAttribute(new Uint32Array(buffer, offset * 4, indicesCount), 1)
            );
            offset += indicesCount;


            geometry.computeBoundingBox();
            const mesh = new THREE.InstancedMesh(geometry, defaultMaterial, elementsCount);
            if(elementsCount > 1) {
               // console.log(i, elementsCount, geometry)
            }
            batches.push({
                index: 0,
                mesh
            });

            this.scene.add(mesh);
        }

        const matrix = new THREE.Matrix4();
        for (let i = 0; i < u32[2]; i++) {
            this.colorsHEXByElementIds[i] = defaultColorHex;
            const elementId = u32[offset + 0];
            const batchId = u32[offset + 1];

            offset += 2;

            matrix.set(
                f32[offset + 0],
                f32[offset + 3],
                f32[offset + 6],
                f32[offset + 9],
                f32[offset + 1],
                f32[offset + 4],
                f32[offset + 7],
                f32[offset + 10],
                f32[offset + 2],
                f32[offset + 5],
                f32[offset + 8],
                f32[offset + 11],
                0,
                0,
                0,
                1
            );
            offset += 12;
            if(this.idsToSkip.includes(String(i))){
                continue
            }

            const batch = batches[batchId];
            this.renderables.set(`${batch.mesh.uuid}#${batch.index}`, elementId);

            batch.mesh.setColorAt(batch.index, getColorByHex(defaultColorHex));
            batch.mesh.setMatrixAt(batch.index, matrix);

            let renderablesArray = this.elements.get(elementId);
            if (renderablesArray === undefined) {
                renderablesArray = [];
                this.elements.set(elementId, renderablesArray);
            }


            const bbox = new THREE.Box3().copy(batch.mesh.geometry.boundingBox).applyMatrix4(matrix);
            renderablesArray.push({
                mesh: batch.mesh,
                index: batch.index,
                bbox
            });

            this.modelBBox.union(bbox);

            batch.index++;
        }

        this.scene.add(new THREE.AmbientLight(0xffffff, 0.5));

        const dirLight1 = new THREE.DirectionalLight(0xddddff, 0.5);
        dirLight1.position.set(1, 4, 2);
        this.scene.add(dirLight1);

        const dirLight2 = new THREE.DirectionalLight(0xffffdd, 0.25);
        dirLight2.position.set(-1, -4, -2);
        this.scene.add(dirLight2);

        this.focusToObjects(null);
        this.animation.startTime -= this.focusTime;

        this.isModelUpdated = true;
    }


    /*
        setElementColorCode(id: WSElementID, colorCode: WSElementColor): void {
            this.colorsHEXByElementIds[id] = colorCode;
            this.setElementColor(id, this.colors[colorCode])
            if(this.hoveredIds.includes(id) || this.selectedIds.includes(id))
                return
        }
    */
    setElementColorHEX(id: WSElementID, colorHEX: HEX): void {

        const element = this.elements.get(id);
        if (element === undefined) {
            if(this.idsToSkip.includes(String(id))) {
                console.log('ID '+id +' is in the list to skip')
            } else {
                console.error(new Error(`Element with id "${id}" not exist.`))
                console.log(colorHEX)
            }
        return
        }
        this.colorsHEXByElementIds[id] = colorHEX
        for (const renderable of element) {
            renderable.mesh.setColorAt(renderable.index, getColorByHex(colorHEX));
            (renderable.mesh.instanceColor as THREE.InstancedBufferAttribute).needsUpdate = true;
        }
        this.isModelUpdated = true;
    }

    private currentElementColors = []
    public setElementColor = (id: WSElementID, colorHEX: HEX): void => {
        this.currentElementColors[id] = colorHEX
        const element = this.elements.get(id);
        if (element === undefined) {
            if(this.idsToSkip.includes(String(id))) {
                console.log('ID '+id +' is in the list to skip')
            } else {
                throw new Error(`Element with id "${id}" not exist.`);
            }
        }

        for (const renderable of element) {
            renderable.mesh.setColorAt(renderable.index, getColorByHex(colorHEX));
            (renderable.mesh.instanceColor as THREE.InstancedBufferAttribute).needsUpdate = true;
        }
        this.isModelUpdated = true;
    }

    deselectElement(id:WSElementID) {

        this.setElementColor(id, this.colorsHEXByElementIds[id]);
    }

    setSelectedElementIds(ids:WSElementID[]) {
        const elementsToDeselect = this.selectedIds.filter(id => !ids.includes(id))
        elementsToDeselect.forEach(id =>
            this.deselectElement(id)
        )
        this.selectedIds = ids;
        this.isModelUpdated = true
    }

    getSelectedElementIds(): WSElementID[] {
        return [...this.selectedIds]
    }

    setHoveredElementIds(ids:WSElementID[]) {
        if(this.hoveredIds.length) {
            this.hoveredIds.forEach(id => {
                    if(this.selectedIds.includes(id)) {

                    }else {
                        this.deselectElement(id)
                    }
                }
            )
        }
        this.hoveredIds = ids;
        this.hoveredIds.forEach(id => {
                if(this.selectedIds.includes(id)) {

                }else {

                }
            }
        )
        this.isModelUpdated = true
    }

    private prevStep = 0
    animateSelection(): void{
        //  console.log('anim '+this.time)
       for(const id of this.hoveredIds) {
            /* if(this.selectedIds.includes(id))
                 return
             const statusColor = this.colors[this.colorCodesByElementIds[id]]
             const statusColorHex = statusColor.getHexString()
             const animatedColorHex = LightenDarkenColor(statusColorHex, (hoveredLoopStep-4)*40)*/
            this.setElementColor(id, '#000000')
        }

        const rest = (this.time) % 0.5
        const ta = rest / 0.5;
        const position = (ta < 0.5 ? ta : 0.5-(ta-0.5)) * 2
        if(!this.animatedColors) {
            let dirty = false
            for (const id of this.selectedIds) {
                if(this.currentElementColors[id] !== '#000000') {
                    this.setElementColor(id, '#000000')
                    dirty = true
                }

            }
            return
        }
        if(!this.animatedColors) {
            const rest = (this.time/3) % 0.5
            const ta = rest / 0.5;
            const position = (ta < 0.5 ? ta : 0.5-(ta-0.5)) * 2


            let step = 0
            if(position< 0.25)
                step = -1
            else if(position> 0.75)
                step = 1
            else
                step = 0
            if(this.prevStep!==position) {
                this.prevStep =position
                let boundStep =  24 + step * 8
                for (const id of this.selectedIds) {
                    const statusColorHex = this.colorsHEXByElementIds[id]
                    const animatedColorHex = getColorAnimTint(statusColorHex, boundStep, 64)//LightenDarkenColor(statusColorHex, (selectedLoopStep  -8)*20)
                    if(step === 0 )
                        this.setElementColor(id, statusColorHex)
                    else if(step === -1)
                        this.setElementColor(id, animatedColorHex)
                    else if(step === 1)
                        this.setElementColor(id, animatedColorHex)
                }
            }
            return
        }
        //  console.log('anim '+this.time)
        const step = Math.round(ta * 32)
        const selectedLoopStep = step> 16 ? 32 - step : step

        const hoveredLoopStep = selectedLoopStep> 8 ? 16 - selectedLoopStep : selectedLoopStep

        for(const id of this.selectedIds) {
            const statusColorHex = this.colorsHEXByElementIds[id]
            const animatedColorHex = getColorAnimTint(statusColorHex, step, 64)//LightenDarkenColor(statusColorHex, (selectedLoopStep  -8)*20)

            this.setElementColor(id, animatedColorHex)
        }

    }

    focusToObjects(ids: Array<WSElementID> | null): void {
        console.log('Call focus to Objects with param ', ids)
        if (ids !== null && ids.length === 0) { return; }

        if (ids === null) {
            tmpBBox.copy(this.modelBBox);
        } else {
            tmpBBox.makeEmpty();
            for (const id of ids) {
                const renderableArray = this.elements.get(id);
                if (renderableArray === undefined) { throw new Error(`Element with id "${id}" not exist.`); }

                for (const renderable of renderableArray) {
                    tmpBBox.union(renderable.bbox);
                }
            }
        }

        const { start, end } = this.animation;

        this.animation.enable = true;
        this.animation.startTime = this.time;

        start.polar = this.controls.getPolarAngle();
        start.distance = this.controls.getDistance();
        start.azimuthal = this.controls.getAzimuthalAngle();
        start.target.copy(this.controls.target);
        end.polar = Math.PI / 2;
        end.distance = tmpBBox.min.distanceTo(tmpBBox.max);
        end.azimuthal = start.azimuthal;
        tmpBBox.getCenter(end.target);
    }

    raycast = (x: number, y: number): number | null => {
        this.ndc.set(
            (x / this.width) * 2 - 1,
            1 - (y / this.height) * 2
        );

        this.raycaster.setFromCamera(this.ndc, this.camera);

        const intersects =  estimate( () =>
       this.raycaster.intersectObjects(this.scene.children), 'raycast')()

        if (intersects.length === 0) { return null; }

        const rendId = `${intersects[0].object.uuid}#${intersects[0].instanceId}`;
        const elementId = this.renderables.get(rendId) as number;
        const castedIds = intersects.map( intersection => {
            const rendId = `${intersects[0].object.uuid}#${intersects[0].instanceId}`;
            const elementId = this.renderables.get(rendId) as number;
            return elementId
        })
        console.log('raycasted elements '+intersects.length,castedIds)
        console.log(intersects)
        if(this.prevDisabledElementIdsMap[elementId])
            return null

        return elementId;
    }

    clear(): void {
        this.scene.traverse(object => {
            if (object instanceof THREE.Mesh) {
                object.geometry.dispose();
            }
        });

        this.scene.clear();
        this.elements.clear();
        this.renderables.clear();
        this.modelBBox.makeEmpty();
        this.animation.enable = false;
    }

    destroy(): void {
        this.clear();
        this.controls.dispose();
        cancelAnimationFrame(this.raf);

        const error = (): number => { throw new Error('WSViewer is destroyed!'); };

        this.setHoveredElementIds = error;
        this.setSelectedElementIds = error;
        this.setElementColorHEX = error;
        this.raycast = error;
        this.destroy = error;
        this.clear = error;
    }

    updateSize(): void {
        const pr = window.devicePixelRatio;
        const width = this.root.clientWidth;
        const height = this.root.clientHeight;

        if (width !== this.width || height !== this.height || pr !== this.pr) {
            this.renderer.setSize(width * pr, height * pr, false);
            this.pr = pr;
            this.width = width;
            this.height = height;
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();

            this.isResized = true;
        }
    }

    animate(): void {
        const { enable, startTime, start, end } = this.animation;
        if (enable === false) { return; }

        if (this.isCameraUpdated === true) {
            this.animation.enable = false;
            return;
        }

        const te = Math.min(1, (this.time - startTime) / this.focusTime);
        const ts = (1 - te);

        const polar = end.polar * te + start.polar * ts;
        const distance = end.distance * te + start.distance * ts;
        const azimuthal = end.azimuthal * te + start.azimuthal * ts;

        const controls = this.controls;
        controls.minPolarAngle = polar;
        controls.maxPolarAngle = polar;
        controls.minDistance = distance;
        controls.maxDistance = distance;
        controls.minAzimuthAngle = azimuthal;
        controls.maxAzimuthAngle = azimuthal;
        controls.target.copy(start.target).multiplyScalar(ts).addScaledVector(end.target, te);
        controls.update();
        controls.minPolarAngle = 0;
        controls.maxPolarAngle = Math.PI;
        controls.minDistance = 0;
        controls.maxDistance = Infinity;
        controls.minAzimuthAngle = Infinity;
        controls.maxAzimuthAngle = Infinity;

        if (te === 1) { this.animation.enable = false; }
    }
}
