import {IonBackButton} from "@ionic/react";
import React from "react";
import {nav} from "../nav";
import {useProject} from "../../../hooks/useProject";

const BackToProjectButton = () => {
    const {projectId} = useProject()
    return <IonBackButton defaultHref={'/app/in/projects/'+projectId} text={"Проект"}/>
}

export default BackToProjectButton/*connectRedux(  state => {
    state.router.location.pathname.split('/')
})
*/
