import {createCRUDDuck} from '@sha/fsa'
import {Action} from "redux";

export type SessionVO = {
    sessionId?: string
    userId?: string
    email?: string
    ip?: string
    createdAt?: string
    updatedAt?: string
    connectionHeaders?: any
}

const duck = createCRUDDuck<SessionVO, 'sessionId'>('sessions', 'sessionId', {}, false)

const actions = {
    ...duck.actions,
    broadcast: duck.factory<{ event: Action, sessions: string[] | 'all' }>('broadcast'),
}

const sessionsDuck = {
    ...duck,
    actions
}


export default sessionsDuck
