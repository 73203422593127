import React from 'react'
import { Eventcalendar, getJson, toast, MbscCalendarEvent, MbscEventcalendarView } from '@mobiscroll/react';
import {useProject} from "../../../hooks/useProject";
import projectsCURD from "iso/src/store/bootstrap/repos/projectsCURD";
import useStatuses from "../../../hooks/useStatuses";
import {IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import BackToProjectButton from "../controls/BackToProjectButton";
import {head, last} from "ramda";
import {MbscCalendarMarked} from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types";

const AgendaScreen = () => {
    const project = useProject()
    const {getStatusById} = useStatuses()

    const max = last(project.events) ? last(project.events).timestamp : new Date().toISOString()
    const min = head(project.events) ? head(project.events).timestamp : new Date().toISOString()
    const marked: MbscCalendarMarked[]  = []
    const markedStatuses: Record<string, boolean> = {}

    const mbEvents: MbscCalendarEvent[] = project.events.filter(e => projectsCURD.actions.stoneStatusesUpdated.isType(e)).map(
        (e: ReturnType<typeof projectsCURD.actions.stoneStatusesUpdated>)=> {
            const status = getStatusById(e.payload.patches[0].statusId)
            const dateRaw = new Date(e.timestamp)
            const date = new Date(dateRaw.getFullYear(), dateRaw.getMonth(), dateRaw.getDate())
            if(markedStatuses[date.toISOString()+'_'+status.color] === undefined) {
                marked.push({
                    color: status.color,
                    date

                })
                markedStatuses[date.toISOString()+'_'+status.color]=true
            }
           return {
               title: status.name +' x '+ e.payload.patches.length ,
               color: status.color,
               start: e.timestamp,
               id: e.guid
           }
        }
    )



    const view = React.useMemo<MbscEventcalendarView>(() => {
        return {
            calendar: { type: 'month'},
            agenda: { type: 'month' }
        };
    }, []);

    const onEventClick = React.useCallback((event) => {
        toast({
            message: event.event.title
        });
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <BackToProjectButton/>
                    </IonButtons>
                    <IonTitle>Сводка</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
        <Eventcalendar
            theme="ios"
            themeVariant="light"
            responsive={{
                xsmall:{
                    view: {
                        calendar: {
                            type:'week',
                            size: 2
                        },

                        agenda: {
                            type:'week',
                            size: 2
                        }
                    }
                },
                small: {
                    view: {
                        calendar: {
                            type:'month'
                        },

                        agenda: {
                            type:'month'
                        }
                    }
                },
                large: {
                    view: {
                        calendar: {
                            type:'month',
                            size:2
                        },

                        agenda: {
                            type:'month',
                            size:2
                        }
                    }
                },
                xlarge: {
                    view: {
                        calendar: {
                            type:'month',
                            size:3
                        },

                        agenda: {

                            type:'month',
                            size:3
                        }
                    }
                }
            }}
            min={min}
            max={max}
            marked={marked}
            dragToCreate={false}
            dragToMove={false}
            dragToResize={false}
            data={mbEvents}
            onEventClick={onEventClick}
        />
            </IonContent>
        </IonPage>
    );
}

export default AgendaScreen