import React, {useCallback, useEffect, useRef} from 'react'
import {CreateAnimation, IonFab, IonFabButton, IonFabList, IonIcon, useIonModal} from '@ionic/react'
import * as Icons from 'ionicons/icons'
import {appsOutline, closeCircleOutline, layers, locateOutline} from 'ionicons/icons'
import useSelectedStoneIds from "../../../../hooks/useSelectedStoneIds"
import useRole from "../../../../hooks/useRole"


export type SingularSelectionTool = {
    toolType: 'singularSelectionTool'
    isMultiple: false
}

export type MultipleSelectionTool = {
    toolType: 'multipleSelectionTool'
    isMultiple: true
}

export type StatusShiftTool = {
    toolType: 'statusShiftTool'
    prevStatusId?: number
    nextStatusId?: number
    isMultiple: false
}

export type RenameTool = {
    toolType: 'renameTool'
    isMultiple: true
}

export type HistoryTool = typeof historyTool

export const getRenameTool = (): RenameTool => {
    return {
        toolType: 'renameTool',
        isMultiple: true
    }
}

export const defaultTool = {
    toolType: 'singularSelectionTool',
    isMultiple: false
} as any as SceneTool

export const historyTool = {
    toolType: 'historyTool' as 'historyTool',
    isMultiple: false
} as const
export type SceneTool = MultipleSelectionTool | SingularSelectionTool | StatusShiftTool | RenameTool | HistoryTool


export type FabSceneToolProps = {
    tool: SceneTool
    onToolChange: (newTool: SceneTool) => any
    onDeselect?: () => any
}

export default ({tool, onToolChange, onDeselect}: FabSceneToolProps) => {
    const {isAdmin} = useRole()
    const setTool = (newTool: SceneTool) => {
        console.log('FabTool:setTool new tool', newTool, ' and prev tool ', tool)
        onToolChange(newTool)
    }
    const fabRef = useRef<HTMLIonFabElement>()

    const aniRef = useRef()
    const [selectedStoneIds, setSelectedStoneIds] = useSelectedStoneIds()
    const getToolIcon = (tool: SceneTool) => {
        if (tool.toolType === 'singularSelectionTool')
            return Icons.locateOutline
        if (tool.toolType === 'multipleSelectionTool')
            return Icons.appsOutline
        if (tool.toolType === 'statusShiftTool')
            return Icons.brush
        if (tool.toolType === 'renameTool')
            return Icons.listCircle
        if (tool.toolType === 'historyTool')
            return Icons.timerOutline
        return Icons.locateOutline
    }
    const onFabDeselectClick = () => {
        setSelectedStoneIds([])
    }

    const onFabClick = () => {
        console.log('FabTool:onFabClick  current tool', tool)
    }


    const onMouseDownOutside = useCallback(() => {
        fabRef.current.close()
    }, [fabRef])


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (fabRef.current && !fabRef.current.contains(event.target)) {
                console.log('FabTool:onClickOutside ')
                onMouseDownOutside && onMouseDownOutside();
            }
        };
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [onMouseDownOutside]);
    const hasSelection = selectedStoneIds.length > 0

    const onStatusShiftButtonClick = async () => {


        const newTool: SceneTool = {
            isMultiple: false,
            toolType: 'statusShiftTool',
        }
        setTool(newTool)
    }

    /*
        <CreateAnimation
            key={'anim'}
            duration={200}
            iterations={1}
            play={true}
            ref={aniRef}

            fromTo={hasSelection
                ?   [
                    { property: 'marginLeft', fromValue: '0px', toValue: '64px' },
                    { property: 'opacity', fromValue: '0', toValue: '1' },
                    { property: 'transform', fromValue: 'rotate(90deg)', toValue: 'rotate(0deg)' },
                ]
                :   [
                    { property: 'marginLeft', toValue: '0px', fromValue: '64px' },
                    { property: 'opacity', toValue: '0', fromValue: '1' },
                    { property: 'transform', toValue: 'rotate(90deg)', fromValue: 'rotate(0deg)' },
                ]
            }
        >
    */
    return <>

        {
            hasSelection &&
            <CreateAnimation
                key={'anim'}
                duration={200}
                iterations={1}
                play={true}
                ref={aniRef}

                fromTo={hasSelection
                    ?   [
                        { property: 'marginLeft', fromValue: '0px', toValue: '64px' },
                        { property: 'opacity', fromValue: '0', toValue: '1' },
                        { property: 'transform', fromValue: 'rotate(90deg)', toValue: 'rotate(0deg)' },
                    ]
                    :   [
                        { property: 'marginLeft', toValue: '0px', fromValue: '64px' },
                        { property: 'opacity', toValue: '0', fromValue: '1' },
                        { property: 'transform', toValue: 'rotate(90deg)', fromValue: 'rotate(0deg)' },
                    ]
                }
            ><IonFab key={'close'} vertical="bottom" horizontal="start" edge={false} slot="fixed" id={'cancelTool'} >
                <IonFabButton color={"danger"} onClick={onFabDeselectClick}>
                    <IonIcon icon={Icons.close}/>
                </IonFabButton>
            </IonFab>
            </CreateAnimation>
        }


        <IonFab vertical="bottom" horizontal="start" edge={false} slot="fixed" ref={fabRef} id={'fabTool'}>
            <IonFabButton onClick={onFabClick} color={ 'primary'} disabled={hasSelection}>
                <IonIcon icon={getToolIcon(tool)}/>
            </IonFabButton>
            <IonFabList side="end">
                <IonFabButton
                    color={tool.toolType === 'singularSelectionTool' ? 'secondary' : 'primary'}
                >
                    <IonIcon icon={locateOutline}
                             onClick={() => setTool({toolType: 'singularSelectionTool', isMultiple: false})}/>
                </IonFabButton>
                {isAdmin &&
                    <IonFabButton
                        color={tool.toolType === 'renameTool' ? 'secondary' : 'primary'}
                    >
                        <IonIcon icon={Icons.listCircle}
                                 onClick={() => setTool(getRenameTool())}/>
                    </IonFabButton>
                }
                <IonFabButton
                    color={tool.toolType === 'historyTool' ? 'secondary' : 'primary'}
                >
                    <IonIcon icon={Icons.timerOutline}
                             onClick={() => setTool({toolType: 'historyTool', isMultiple: false})}/>
                </IonFabButton>
            </IonFabList>
            <IonFabList side="top">
                <IonFabButton onClick={() =>
                    setTool({toolType: 'multipleSelectionTool', isMultiple: true})
                } color={tool.toolType === 'multipleSelectionTool' ? 'secondary' : 'primary'}>
                    <IonIcon icon={appsOutline}/>
                </IonFabButton>
                <IonFabButton
                    onClick={() =>
                        onStatusShiftButtonClick()
                    }
                    color={tool.toolType === 'statusShiftTool' ? 'secondary' : 'primary'}>
                    <IonIcon icon={Icons.brush}/>
                </IonFabButton>
            </IonFabList>
        </IonFab>

    </>
}
