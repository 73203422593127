import React, { useRef, useState } from "react";
import * as R from "ramda";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { uiDuck } from "../../../store/ducks/uiDuck";
import { useHistory, useLocation } from "react-router";
import { nav } from "../nav";
import { Virtuoso } from "react-virtuoso";
import { ExtractProps } from "@sha/react-fp";
import { useSubscribe } from "../../../contexts";
import Highlighter from "react-highlight-words";
import { useProject } from "../../../hooks/useProject";
import EmptyView from "../controls/EmptyView";
import useModalRoute from "../../../hooks/useModalRoute";
import ModalPresentingElementContext from "../../../contexts/ModalPresentingElementContext";
import * as Icons from "ionicons/icons";
import { flatten } from "ramda";
import { arrify } from "@sha/utils";
const spacesRegExp: RegExp = /\S+/g;

const splitSpaces = (str: string) => {
  return Array.from(str.matchAll(/\S+/g)).map((m) => m[0]);
};

function StoneSearchModal(props: ExtractProps<typeof IonModal>) {
  const modal = useRef<HTMLIonModalElement>(null);
  const modalPresenter = useSubscribe(ModalPresentingElementContext);
  const dispatch = useDispatch();
  let project = useProject();
  const inputRef = useRef<HTMLIonSearchbarElement>();
  const projectId = project.projectId;
  const pre = projectId.length <= 2 ? (projectId + "-L").toUpperCase() : "";
  const [value, setValue] = useState(pre);
  const [searchText, setSearchText] = useState(pre);

  const stones = project.stones;

  const [regExps, setRegExps] = useState([new RegExp(pre, "gi")]);

  const options = searchText
    ? stones.filter((s) => regExps.some((reg) => s.name.match(reg)))
    : stones;

  const onSearchInput = (text: string) => {
    console.log("search", searchText);
    const terms = splitSpaces(text);
    const exps = terms.map((term) => new RegExp(term, "gi"));
    setRegExps(exps);
    console.log("regExps", exps);
    console.log("terms", terms);
    setSearchText(text);
  };

  const onSelect = async (ids: string[]) => {
    if (ids && ids.length) {
      dispatch(uiDuck.actions.searchResultSelected({ ids }));
      dispatch(uiDuck.actions.setSelection(ids));
      console.log("onSelect");
    }
    await modal.current.dismiss();
  };

  const onSelectAll = () => {
    onSelect(options.map((o) => o.id));
  };
  const onChange = (data: string) => {
    setValue(data);
  };

  const [isOpen, onWillDismiss] = useModalRoute(
    nav.project({ projectId }) + "?search"
  );
  return (
    <IonModal
      animated={true}
      ref={modal}
      onWillDismiss={onWillDismiss}
      onIonModalDidPresent={() => {
        inputRef.current.setFocus();
      }}
      isOpen={isOpen}
      {...props}
    >
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Поиск элемента</IonTitle>
          <IonButtons slot="end">
            <IonButton mode="ios" onClick={() => modal.current?.dismiss()}>
              <IonIcon icon={Icons.close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar mode="ios">
          <IonSearchbar
            ref={inputRef}
            debounce={300}
            placeholder="Название элемента..."
            value={searchText}
            onIonInput={(e) => onSearchInput(e.detail.value)}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {options.length ? (
          <Virtuoso
            style={{ height: "100%" }}
            totalCount={options.length}
            itemContent={(index) => {
              const opt = options[index];
              const name = opt.name;
              const matches = regExps.map((reg) =>
                Array.from(name.matchAll(reg))
              );
              const notEmptyMatches = matches.filter((m) => m !== undefined);
              const flattenMatches = flatten(notEmptyMatches);
              const bestMatch = R.sortBy(
                R.prop("length"),
                flattenMatches
              ).pop();

              return (
                <IonItem onClick={(e) => onSelect([opt.id])}>
                  <IonLabel>
                    {bestMatch ? (
                      <Highlighter
                        highlightClassName={
                          bestMatch.length === name.length
                            ? "search-highlight__full-match"
                            : "search-highlight"
                        }
                        searchWords={[new RegExp(bestMatch, "i")]}
                        textToHighlight={opt.name}
                      />
                    ) : (
                      <Highlighter
                        highlightClassName={"search-highlight"}
                        searchWords={[]}
                        textToHighlight={opt.name}
                      />
                    )}
                  </IonLabel>
                </IonItem>
              );
            }}
          />
        ) : (
          <EmptyView description={"Нет совпадений"} />
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton
            id={"add-comment-modal"}
            expand={"full"}
            onClick={(e) => onSelectAll()}
          >
            Выбрать все: {options.length}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
}

export default StoneSearchModal;
