import React from 'react'
import {useLocation, useParams, useRouteMatch} from "react-router";
import {IonTitle} from "@ionic/react";
import useRouteProps from "../../../hooks/useRouteProps";

export default ({children}: { children: React.ReactNode }) => {
    let match = useRouteMatch()
    //const scene = useProject()
    let params = useParams()
    const location = useLocation()
    const props = useRouteProps()
    /*  console.log('----')
      console.log('path', match.path)
      console.log('children', children)
      console.log('routeProps', props)
      console.log('useLocation', useLocation())
      console.log('useParams', useParams())
      console.log('useRouteMatch', useRouteMatch())
      console.log('useHistory', useHistory())*/
    return <IonTitle>{children}</IonTitle>
}
