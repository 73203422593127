import {IonRoute, IonRouteProps} from "@ionic/react";
import React from "react";

export default (props: IonRouteProps) => {

    return <IonRoute
        {...props}
        render={routeProps => {

            ///console.log('WSIonRoute ', routeProps.location.pathname, routeProps, props)
            return props.render(routeProps)
        }
        }
    />
}
