import {projectsCRUD, settingsDuck, usersCRUD} from './bootstrap'

export {default as connectionDuck} from './sse/sseConnectionDuck'
export {default as connectionSaga} from './sse/sseClientSaga'
import {defaultMeta, metaDuck} from './metaDuck'

export const ducks  = {
    settingsDuck,
    metaDuck,
    usersCRUD,
    projectsCRUD
}
export {metaDuck}

export type StoreMeta = typeof defaultMeta

