import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Label,
} from "recharts";
import projectsCURD, {
  ProjectVO,
} from "iso/src/store/bootstrap/repos/projectsCURD";
import { useMount } from "react-use";
import { useDispatch } from "react-redux";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import { Props as LabelProps } from "recharts/types/component/Label";
import settingsDuck from "iso/src/store/bootstrap/settingsDuck";

import "./chart.scss";
import {calculateMinWidthContainer, percentFormatter, Rect, Sector, toPercent} from "./common";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const sectorStatuses = {
  bottom: "Принят стройконтролем",
  center: "Смонтирован",
  top: "Доставлен/принят",
};

const noStatStatusId = [1,101,109]
export default ({ projects }: { projects: ProjectVO[] }) => {
  const statuses = useFrontSelector(settingsDuck.selectStatuses);

  const getLabelColor = (status: string) =>
    statuses.find(({ name }) => name === status)?.color;
  const getColumnLabelColor = (sector: Sector) =>
    getLabelColor(sectorStatuses[sector]);

  //TODO в модели статуса на на что завязаться, кроме как на name (на русском). Думаю было бы лучше в модели использовать
  // что-то вроде {name: 'mounted', label: 'Смонтирован'}
  const data = projects?.map((p) => {
    const item = {
      name: p.analyticsName || p.name,
      ["Доставлен/принят"]: 0,
      ["Смонтирован"]: 0,
      ["Принят стройконтролем"]: 0,
    };
    p.stones.forEach((s) => {
      if (s.statusId <= 10) {
        item["Принят стройконтролем"] += s.statusId === 10 ? 1 : 0;
        item["Смонтирован"] += s.statusId === 9 ? 1 : 0;
        item["Доставлен/принят"] += s.statusId === 7 || s.statusId === 8 ? 1 : 0;
      }
    });

    const isNoStatStatusId = (statusId: number) => {
      return noStatStatusId.includes(statusId)
    }
    const length =p.stones.filter(s => !isNoStatStatusId(s.statusId)).length
    item["Смонтирован"] = toPercent(length, item["Смонтирован"]);
    item["Доставлен/принят"] = toPercent(length, item["Доставлен/принят"]);
    item["Принят стройконтролем"] = toPercent(
      length,
      item["Принят стройконтролем"]
    );
    return item;
  });

  let rects: Record<string, Record<Sector, Rect>> = {};
  const saveRect = (sector: Sector) => (rect: Rect, name) => {
    if (!rects[name]) rects[name] = {};
    rects[name][sector] = rect;
  };

  const renderCustomizedLabels = (props: LabelProps) => {
    const proj = data.find((p) => p.name === props.name);
    const { x, y, width, value, height } = props;
    console.log(props, proj);

    const labelHeight = 20;
    const labelPadding = (width - 80) / 2;

    let bottomRect;
    let centerRect;
    let topRect;
    const renderLabel = (sector: Sector) => {
      const rect = rects[proj.name][sector];
      const value =
        sector === "bottom"
          ? proj["Принят стройконтролем"]
          : sector === "center"
          ? proj["Принят стройконтролем"] + proj["Смонтирован"]
          : proj["Принят стройконтролем"] +
            proj["Смонтирован"] +
            proj["Доставлен/принят"];
      const display = percentFormatter(value);
      const { x, y, width, height } = rect;
      const actualHeight = height > labelHeight ? labelHeight : labelHeight;
      console.log("render " + sector, rect);

      let inner = {
        x: x + labelPadding,
        y: y + height / 2 - actualHeight / 2,
        width: width - labelPadding * 2,
        height: actualHeight,
      };

      if (sector === "bottom") {
        if (inner.height > height) {
          inner.y = y - inner.height;
        }
        bottomRect = inner;
      }
      if (sector === "center") {
        if (inner.y + inner.height > y + height) {
          inner.y = y + height - inner.height;
        }
        if (inner.height + inner.y > bottomRect.y) {
          inner.y = bottomRect.y - inner.height;
        }
        centerRect = inner;
      }
      if (sector === "top") {
        if (inner.y + inner.height > y + height) {
          inner.y = y + height - inner.height;
        }
        if (inner.height + inner.y > centerRect.y) {
          inner.y = centerRect.y - inner.height;
        }
        topRect = inner;
      }
      return (
        <g>
          <rect {...inner} fill={getColumnLabelColor(sector)} />
          <text
            x={Number(x) + Number(width) / 2}
            y={Number(inner.y) + Number(inner.height) / 2 + 2}
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="middle"
            filter='drop-shadow(1px 1px 0.5px black)'
          >
            {display}
          </text>
        </g>
      );
    };

    return [renderLabel("bottom"), renderLabel("center"), renderLabel("top")];
  };
  return (
    <div style={{ width: "100%", height: "80%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        <ResponsiveContainer
          width="100%"
          height="97%"
          minWidth={`${calculateMinWidthContainer(data?.length)}px`}
        >
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 50,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              yAxisId="left"
              type="number"
              domain={[0, 100]}
              max={100}
              unit={"%"}
            />
            <YAxis
              yAxisId="right"
              type="number"
              orientation="right"
              domain={[0, 100]}
              max={100}
              unit={"%"}
            />
            {
              // <Tooltip formatter={percentFormatter} />
            }
            <Legend
              style={{ paddingTop: "60px" }}
              iconType={"circle"}
              iconSize={24}
            />

            <Bar
              yAxisId="left"
              dataKey="Принят стройконтролем"
              stackId="a"
              fill={getLabelColor("Принят стройконтролем")}
            >
              <LabelList
                content={({ x, y, width, height, name }) =>
                  saveRect("bottom")({ x, y, width, height }, name)
                }
                dataKey={"Принят стройконтролем"}
                color={"black"}
                position="bottom"
                formatter={percentFormatter}
              />
            </Bar>
            <Bar
              yAxisId="left"
              dataKey="Смонтирован"
              stackId="a"
              fill={getLabelColor("Смонтирован")}
            >
              <LabelList
                content={({ x, y, width, height, name }) =>
                  saveRect("center")({ x, y, width, height }, name)
                }
                dataKey={"Смонтирован"}
                color={"black"}
                position="center"
                formatter={percentFormatter}
              />
            </Bar>
            <Bar
              yAxisId="left"
              dataKey="Доставлен/принят"
              stackId="a"
              fill={getLabelColor("Доставлен/принят")}
            >
              <LabelList
                content={({ x, y, width, height, name }) =>
                  saveRect("top")({ x, y, width, height }, name)
                }
                dataKey={"Доставлен/принят"}
                color={"black"}
                position="top"
                formatter={percentFormatter}
              />
              <LabelList
                content={renderCustomizedLabels}
                dataKey={"Смонтирован"}
                color={"black"}
                position="bottom"
                formatter={percentFormatter}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
