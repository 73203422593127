import React, {useCallback, useEffect, useRef, useState} from 'react'

import {useDispatch} from "react-redux"
import {uiDuck} from "../../../store/ducks/uiDuck"

import ModalPresentingElementContext from '../../../contexts/ModalPresentingElementContext'
import SelectionFab, {defaultTool, SceneTool} from "./tools/FabTool";
import {IonContent, IonFab, IonFabButton, IonIcon, IonPage, IonToast} from '@ionic/react';
import SceneHeader from "./SceneHeader";
import StoneSearchModal from "./StoneSearchModal";
import {useProject} from "../../../hooks/useProject";
import WSReactViewer, {ReadyViewer} from "./WSReactViewer";
import ViewerBasicTool from "./ToolSingle";
import ViewerContext from './tools/ViewerContext'
import ToolMultiple from "./ToolMultiple"
import ToolBrush from "./ToolBrush"
import {useLocation} from "react-use"
import ToolRename from "./ToolRename"
import FabSweep from "./tools/FabSweep"
import {useKeyPressed} from "../../../hooks/common/useKeyPressed"
import useSweepMode from "../../../hooks/common/useSweepMode"
import useSelectedStoneIds from "../../../hooks/useSelectedStoneIds"
import {useUrlSearchParams} from "../../../hooks/useUrlSearchParams"
import {ViewerOrbitControls} from "scene/src/utils"
import ToolHistory from "./ToolHistory";
import {videocamOff} from 'ionicons/icons'

export type SceneURLParams = {
    cam: ViewerOrbitControls
}
const urlParamsTypes = {
    x0: Number,
    y0:Number,
    z0: Number,
    x: Number,
    y:Number,
    z: Number,
    stoneId: String
}
const MapPage = ({projectId, ...props}) => {
    const dispatch = useDispatch()
    const page = useRef(null);
    const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);
    useEffect(() => {
        setPresentingElement(page.current);
    }, []);

    const [tool, setTool] = useState(defaultTool as SceneTool)

    const onToolChange = (newTool: SceneTool) => {
        dispatch(uiDuck.actions.setMode(newTool.isMultiple ? 'multiple' : 'singular'))
        setTool(newTool)
        console.info('ScenePage:setTool', newTool)
        setSelectedStoneIds([])
        sweepOff()
    }

    const project = useProject()

    const [selectedStoneIds, setSelectedStoneIds] = useSelectedStoneIds()


    const {isSweepMode,sweepOn, sweepOff} = useSweepMode()
    const [readyViewer, setReadyViewer] = useState(undefined as ReadyViewer)
    const location = useLocation()
    const toolElement =  props.match.isExact
    ? tool.toolType === 'singularSelectionTool'
        ? <ViewerBasicTool key={'singularSelectionTool'}/>
        : tool.toolType === 'multipleSelectionTool'
            ? <ToolMultiple key={'multipleSelectionTool'} />
            : tool.toolType === 'statusShiftTool'
                ? <ToolBrush key={'s'}/>
                : tool.toolType === 'historyTool'
                    ? <ToolHistory key={'history'}/>
                    :<ToolRename key={'rename'}/>
        : null

    return (
        <ModalPresentingElementContext.Provider value={page.current} >
        <IonPage ref={page}>

            <ViewerContext.Provider value={readyViewer}>
                <ModalPresentingElementContext.Provider value={page.current}>
                    <SceneHeader projectId={projectId}/>
                    <IonContent>
                        {isSweepMode && <IonFab vertical={'top'} horizontal={'center'}>

                                <IonIcon icon={videocamOff} size={'36px'}></IonIcon>

                        </IonFab>
                        }
                        <WSReactViewer
                            onReady={setReadyViewer}
                        />
                        <FabSweep/>

                        <SelectionFab key={'fab'} tool={tool} onToolChange={onToolChange}/>

                    </IonContent>
                    {toolElement}

                </ModalPresentingElementContext.Provider>
            </ViewerContext.Provider>
        </IonPage>
            <StoneSearchModal
                swipeToClose={false}
                animated={false}
            />
        </ModalPresentingElementContext.Provider>
    )

}

export default React.memo(MapPage)
