import React from 'react'
import {connect, useSelector} from 'react-redux'


import {FrontState} from "../store/frontReducer";
import {uiDuck} from "../store/ducks/uiDuck";

import {IonApp, IonLoading, IonToast} from '@ionic/react'
import RootOutlet from "./screens/RootOutlet";

import FPSStats from "react-fps-stats";
import {useLocalStorage} from "react-use";
import usePrefKey from "../hooks/common/usePrefKey";
import PushEventsUIFeedback from "./screens/PushEventsUIFeedback";
export default connect(state => ({state}), dispatch => ({dispatch}))(
    ({state, dispatch, history}: { state: FrontState, dispatch: Function, history }) => {

        const [showFPS] = usePrefKey('showFPS')
        const ui = useSelector(uiDuck.selectUI)

// className={"dark-theme"}>
        return (
            <IonApp className={'ws-app'}>
                {ui.preloaded && <RootOutlet history={history}/>}
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={!ui.preloaded}
                    message={'Загрузка UI'}
                    duration={0}
                />
                {
                    showFPS &&
                    <FPSStats top={80} left={10} style={{opacity: '0.3'}}/>
                }
                <PushEventsUIFeedback/>
            </IonApp>
        )
        /*<ConnectedRouter history={historySubscription}>
                    <Switch>
                        {routes}
                    </Switch>
                </ConnectedRouter>*/


    }
)
