import React, {useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonToolbar,
    useIonViewWillEnter
} from '@ionic/react';

// Direct React component imports
// import Swiper core and required modules
import {Swiper as SwiperCore} from 'swiper';
import {arrowForward} from 'ionicons/icons';

import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// Styles must use direct files imports
import '@ionic/react/css/ionic-swiper.css';
import './Tutorial.scss';
import {useHistory} from 'react-router';
import {useProject} from "../../../hooks/useProject";
import {nav} from "../nav";
import {Icons} from "../controls/Icons";

export default () => {
    const history = useHistory()
    const [showSkip, setShowSkip] = useState(true);
    let [swiper, setSwiper] = useState<SwiperCore>();

    useIonViewWillEnter(() => {
        if (swiper)
            swiper.slideReset()
    })

    const project = useProject()

    const completeTutorialHref = nav.project({projectId: project.projectId})


    const handleSlideChangeStart = () => {
        if (!swiper) return;
        setShowSkip(!swiper.isEnd);
    };

    return (
        <IonPage id="tutorial-page">
            <IonHeader no-border>
                <IonToolbar>
                    <IonButtons slot="end">
                        {showSkip &&
                            <IonButton color='primary' routerLink={completeTutorialHref}>Пропустить</IonButton>}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <Swiper onSwiper={setSwiper} onSlideChangeTransitionStart={handleSlideChangeStart}>
                    <SwiperSlide>
                        <img src="/assets/img/ica-slidebox-img-1.png" alt="" className="slide-image"/>
                        <h2 className="slide-title">
                            <b>Скриншоты Строй-мониторинг</b>
                        </h2>
                        <p>Выберите проект здания и роль ведения отчётности. </p>
                        <p>
                            Ознакомьтесь со списком статусов и списком дсотупных вам ролей поссылке.
                        </p>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src="/assets/img/ica-slidebox-img-2.png" alt="" className="slide-image"/>
                        <h2 className="slide-title">GIF записанного экрана, с переклчюением режимов. Используйте разные
                            режимы указания деталей</h2>
                        <p>
                            <IonIcon icon={Icons.locateOutline}></IonIcon> <b>Выбирайте элементы по одному</b>
                        </p>
                        <p>
                            <IonIcon icon={Icons.appsOutline}></IonIcon> <b>Выбирайте несколько элементов сразу</b>
                        </p>
                        <p>
                            <IonIcon icon={Icons.reloadCircle}></IonIcon> <b>Выбирайте элементы только с определённым
                            статусом</b>
                        </p>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src="/assets/img/ica-slidebox-img-3.png" alt="" className="slide-image"/>
                        <h2 className="slide-title">Отчёты</h2>
                        <p>
                            <b>Запланировны в следующих версиях</b>
                        </p>
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src="/assets/img/ica-slidebox-img-4.png" alt="" className="slide-image"/>
                        <h2 className="slide-title">Начать работу</h2>
                        <IonButton fill="clear" routerLink={completeTutorialHref}>
                            Вперёд
                            <IonIcon slot="end" icon={arrowForward}/>
                        </IonButton>
                    </SwiperSlide>
                </Swiper>
            </IonContent>
        </IonPage>
    );
};
