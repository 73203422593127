import React from 'react'
import {Provider} from 'react-redux'
import {ConnectedRouter, history} from '@sha/router'
import {HistoryContext} from './contexts'
import DesktopRoot from './components/UIRoot'

const App = ({store}) => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history} omitRouter={false}>
                <HistoryContext.Provider value={history}>
                    <DesktopRoot history={history}/>
                </HistoryContext.Provider>
            </ConnectedRouter>
        </Provider>

    )

}
/*
<ConnectedRouter history={history} omitRouter={true}>
                        <DesktopRoot />
                    </ConnectedRouter>
 */
export default App
