import {IonFab, IonFabButton, IonIcon} from "@ionic/react"
import React, {useCallback} from "react"
import {Icons} from "../../controls/Icons"
import useSweepMode from "../../../../hooks/common/useSweepMode"

export default () => {

    const {isSweepMode,sweepOn,sweepOff} = useSweepMode()

    return  <IonFab vertical="center" horizontal="start" edge={false} slot="fixed" id={'fabSweep'}>
                <IonFabButton onClick={isSweepMode ? sweepOff: sweepOn}  color={isSweepMode? 'danger' : 'white'}>
                    <IonIcon icon={isSweepMode ? Icons.flash: Icons.flashOff}/>
                </IonFabButton>
            </IonFab>
}