import React, {useState} from 'react'
import useFrontDispatch from "../../../hooks/common/useFrontDispatch";
import WSSelect from "../controls/WSSelect";
import * as R from 'ramda'
import {nav} from "../nav"
import useFrontSelector from "../../../hooks/common/useFrontSelector"
import projectsCURD, {ProjectVO} from "iso/src/store/bootstrap/repos/projectsCURD"
import {
    IonButton, IonButtons,
    IonCard,
    IonContent,
    IonFooter,
    IonHeader, IonIcon,
    IonItem,
    IonLabel, IonList,
    IonPage, IonSelect, IonSelectOption, IonText, IonTitle,
    IonToolbar
} from '@ionic/react';
import settingsDuck from "iso/src/store/bootstrap/settingsDuck";
import {useHistory} from "react-router";
import IonCurrentRoute from "../controls/IonCurrentRoute";
import WsContent from "../controls/WsContent";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useStatuses from "../../../hooks/useStatuses";
import {uiDuck} from "../../../store/ducks/uiDuck";
import {Icons} from "../controls/Icons";
import {RoleDescriptionList} from "./RoleDescriptionScreen";
import ExitButton from '../controls/ExitButton'
import RoleSelector from '../controls/RoleSelector'
import useRole from '../../../hooks/useRole'
import ProjectSelector from '../controls/ProjectSelector'


export default () => {
    const {currentUser, availableRoles} = useCurrentUser()
    const history = useHistory()
    const {currentRole} = useRole()
    const roleId = currentRole.roleId
    const dispatch = useFrontDispatch()
    const projects: ProjectVO[] = useFrontSelector(projectsCURD.selectList).reverse()//.filter(p => p.projectId !== '0')

    const [projectId, setProjectId] = useState(R.last(projects).projectId as string)
    const [projectGroup, setProjectGroup] = useState('facade')

    const project = projectId ? projects.find(p => p.projectId === projectId) : undefined


    const onSubmit = async () => {
        dispatch(uiDuck.actions.setSelection([]))
        const url = nav.project({projectId})
        history.push(url)
    }

    const onStatsClick =async () => {
        const statsProjects = projects
        const url = nav.charts({projectGroup})
        history.push(url)
    }

  //  console.log('roleId', roleId, role)

    const [open, setOpen] = useState(false)
    return (

        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <ExitButton />
                    </IonButtons>
                    <IonTitle>Главная</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                {
                    currentUser.statistics && <IonCard>

                        <IonItem>
                            <IonSelect  label={'Графики'} okText={'Выбрать'}
                                        onIonChange={(e) => setProjectGroup(e.detail.value)}
                                       cancelText={'Отмена'} aria-label="fruit" value={projectGroup} placeholder="Выберите группу проектов"
                                        >
                                <IonSelectOption key='facade' value="facade">Фасадные работы</IonSelectOption>
                                <IonSelectOption key='oranges' value="oranges">Окна</IonSelectOption>
                            </IonSelect>
                        </IonItem>

                        <IonButton
                            expand={'block'}
                            fill={'outline'}
                            onClick={onStatsClick}
                        >
                            Посмотреть
                        </IonButton>

                    </IonCard>
                }
                    <IonCard>

                        <ProjectSelector projectId={projectId} onProjectChange={setProjectId}></ProjectSelector>
                       <RoleSelector layer={project? project.projectStatusesType : undefined}></RoleSelector>
                        {
                            roleId ?
                                open
                                    ? <RoleDescriptionList roleId={roleId}  />
                                    :    <IonButton fill="clear" expand={'block'} onClick={() => setOpen(true)}>Описание роли</IonButton>
                                : <p>
                                    <IonText color="warning">
                                        <IonIcon name="warning"></IonIcon></IonText>
                                    Выбери роль, доступную вам в проекте
                                </p>
                        }

                    </IonCard>


            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonButton expand={'block'} disabled={roleId === 0 || roleId===undefined || projectId === undefined}
                               onClick={onSubmit}>Выбрать</IonButton>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    )
}
