import useViewer from "./useViewer";
import {useCallback, useEffect, useState} from "react";
import {isTouchDevice} from "../../../../utils/isTouchDevice";
import {useIonToast} from "@ionic/react";
import {usePrevious} from "react-use";
import useSelectedStoneIds from "../../../../hooks/useSelectedStoneIds";
import {useProject} from "../../../../hooks/useProject";
import useFrontSelector from "../../../../hooks/common/useFrontSelector";
import {uiDuck} from "../../../../store/ducks/uiDuck";
import settingsDuck from "iso/src/store/bootstrap/settingsDuck";
import {StoneStatus} from "iso/src/store/bootstrap/StatusesMatrix";
import {WSElementID} from "scene/src/AttachedViewer";
import {StoneVO} from "iso/src/store/bootstrap/repos/projectsCURD";
import {estimate} from "@sha/utils";
import useSweepMode from "../../../../hooks/common/useSweepMode";
import StoneEvent from "./StoneEvent";
import {DateTime} from "luxon";
import {projectsCRUD} from "iso/src/store/bootstrap";
import {useSelector} from "react-redux";
import {equals} from "ramda";
import useRole from '../../../../hooks/useRole'
import useStones from '../../../../hooks/useStones'
let forwardStoneClick

export type ColorMapper = (id: StoneVO) => string
const defaultColorMapper = ()=> {}
export default ({onStoneClick, onStoneHover, colorMapper, lastTimestamp,...props}:{
    onStoneClick?: (id: string, e?: MouseEvent ) =>  any
    onStoneHover?: (id: string, e?: MouseEvent) => any
    colorMapper?: ColorMapper,
    lastTimestamp?: DateTime|string
}): [string[], (ids: string[]) => any, StoneVO[]] => {
    const {isAdmin}= useRole()

    const {getStoneById, isNoSelectStatusId,isNoStatStatusId} = useStones()
    const state = useSelector(state => state)
    const {isSweepMode} = useSweepMode()
    const settings = useFrontSelector(settingsDuck.selectSettings)
    const statusesByIds: Record<number, StoneStatus> = {}
    settings.statuses.forEach(s => statusesByIds[s.statusId] = s)
    const {viewer, canvas, getStoneIDByEvent} = useViewer()

    const [present] = useIonToast()
    const project = useProject()
    const allStones = lastTimestamp ? projectsCRUD.selectHistoryProject(project.projectId,lastTimestamp)(state).stones: project.stones
    const [selectedStoneIds, setSelectedStoneIds] = useSelectedStoneIds()
    const selection = useFrontSelector(uiDuck.selectSelection)

   if(viewer){//  &&( prevSelected !== selectedStoneIds)){
       /* console.log(
            'useInteractiveStones: selectedStoneIds', selectedStoneIds
        )*/
        viewer.setSelectedElementIds(selectedStoneIds.map(i => Number(i)))
    }
    const prevStones = usePrevious(!Boolean(viewer) || allStones)

   const defaultColorMapper = useCallback((stone: StoneVO) => {
       const status = statusesByIds[stone.statusId]
       if(!status)
           debugger
       return statusesByIds[stone.statusId].color
   },[settings])

   const currentColorMapper = colorMapper || defaultColorMapper

    const prevColorMapper = usePrevious(currentColorMapper)

    const colorMapperChanged = prevColorMapper!==currentColorMapper
    if(viewer){

        if ( !equals(prevStones, allStones) || colorMapperChanged)
        {
            allStones.forEach(s => {
                if (!prevStones ||
                    !prevStones[String(s.id)] ||
                    prevStones[String(s.id)].statusId !== s.statusId ||
                    prevStones[String(s.id)].name !== s.name ||
                    colorMapperChanged
                )
                    viewer.setElementColorHEX(Number(s.id), currentColorMapper(s))
            })
        }

        if(viewer.getControls().enabled !== !isSweepMode){
            viewer.getControls().enabled = !isSweepMode
            viewer.getControls().update();
        }
    }


    forwardStoneClick = onStoneClick
    useEffect(() => {
        if (!viewer)
            return
        let prevClickedStoneId = undefined as string
        let isSweepMode = !viewer.getControls().enabled
        let isPressed = false
        const mouseDownHandler = e => {

            isPressed = true
            isSweepMode = !viewer.getControls().enabled
            const stringId = getStoneIDByEvent(e)
            const stone = getStoneById(stringId)
            if(!isAdmin && (
                is
            )) {
                return
            }
            if(isSweepMode && viewer.getSelectedElementIds().includes(Number(stringId))){
                return
            }

            if (forwardStoneClick) {
                prevClickedStoneId = stringId
                const result = forwardStoneClick(stringId)
                if (result)
                    present({
                        message: 'Нельзя выбрать ' + result
                    })
            }
        }


        const onActivateStone = (event: StoneEvent) => {
           const getStoneById = (stoneId) => {
               const state = window.store.getState()
               const proj = projectsCRUD.selectById(project.projectId)(state)
               const stone = proj.stones.find(s => s.id === stoneId)

               return stone
           }
            const stringId =event.stoneId
            const stone = getStoneById(stringId)
            const isAdmin = uiDuck.selectRole(window.store.getState()) === 1
            console.log(stone)
            if( (
              isNoSelectStatusId(  stone.statusId)// === 1 || stone.statusId === 101
            )) {
                if(!isAdmin)
                return
            }
            onStoneClick(event.stoneId)
        }

        const initialize = () => {

            const isTouchEnabled = isTouchDevice()
/*
            canvas.addEventListener('mousemove', mouseMoveHandler);
            canvas.addEventListener('mousedown', mouseDownHandler);
            canvas.addEventListener('long-press', mouseLongPressHandler)
            if (!isTouchEnabled) {
                canvas.addEventListener('mouseleave', mouseLeaveHandler)
                canvas.addEventListener('mouseout', mouseLeaveHandler)
                canvas.addEventListener('mouseup', mouseLeaveHandler)
            }*/

            window.addEventListener('activateStone', onActivateStone)
            console.log('useHoverHighlist Started')
        }
        const dispose = () => {
            window.removeEventListener('activateStone', onActivateStone)
            /*
            if (canvas) {
                canvas.removeEventListener('mousemove', mouseMoveHandler);
                canvas.removeEventListener('mousedown', mouseDownHandler);
                canvas.removeEventListener('mouseleave', mouseLeaveHandler)
                canvas.removeEventListener('mouseout', mouseLeaveHandler)
                canvas.removeEventListener('mouseup', mouseLeaveHandler)
                canvas.removeEventListener('long-press', mouseLongPressHandler)
            }
*/
            console.log('useHoverHighlist DISPOSED')
        }
        const promise = initialize()
        return dispose
    }, [viewer])

    return [selectedStoneIds,setSelectedStoneIds, allStones]

}
