import React, {useState} from 'react'
import {
    IonActionSheet,
    IonButton,

    IonContent, IonIcon,
    IonItem,
    IonLabel,
    IonList, IonNote,
    IonPopover, IonRadio,
    IonRadioGroup
} from "@ionic/react";

import useSelectedStones from "../../hooks/useSelectedStoneIds";
import {ExtractProps} from "@sha/react-fp";
import useStatuses from "../../hooks/useStatuses";
import StatusItem from "./StatusItem";
import useRole from "../../hooks/useRole";
import useCurrentUser from "../../hooks/useCurrentUser";
import useProjectStatuses from '../../hooks/useProjectStatuses'
import {useProject} from '../../hooks/useProject'

type StatusSelectorProps = {
    statusId: number
    availableStatusIds?: number[]
    onStatusChange?: (newStatusId: number) => any
    children?: React.ReactElement
    enableAnyStatus?: boolean
    disabled?:boolean
} & ExtractProps<typeof StatusItem>

const StatusSelector = ({onStatusChange,statusId, children, enableAnyStatus, disabled, ...props}: StatusSelectorProps) => {
    const project = useProject()
    const {currentRole, isAdmin} = useCurrentUser()
    const {getStatusById,allStatuses} = useStatuses()
    const {projectStatuses} = useProjectStatuses(project.projectId)
    const availableStatusIds = isAdmin
        ? projectStatuses.map(s => s.statusId)
        : getStatusById(statusId).nextStatusesIds.filter(id => currentRole.destinationStatusesIds.includes(id))

    const [open, setOpen] = useState(false)
    const status = getStatusById(statusId)
    const onStatusItemClick = (nextStatusId) => {
        onStatusChange(nextStatusId)
        setOpen(false)
    }


    if(!statusId)
        return <IonList>
            <IonPopover  animated={false} size={'auto'} keepContentsMounted={true}  side={'top'} isOpen={open} onDidDismiss={() => setOpen(false)} dismissOnSelect={true}>

                <IonList>
                    {
                        availableStatusIds.length
                            ?availableStatusIds.map(nextStatusId =>
                                <StatusItem lines={'none'}
                                            selected={nextStatusId === statusId}
                                            statusId={nextStatusId}
                                            onClick={ () => {
                                                onStatusItemClick(nextStatusId)
                                            }}
                                >

                                </StatusItem>
                            )
                            : <IonItem color={'medium'} lines={'none'} >
                                <IonLabel>Нет доступных статусов</IonLabel>
                                </IonItem>

                    }

                </IonList>

            </IonPopover>

            <IonItem
                        button
                        onClick={() => !disabled && setOpen(true)}
                        lines={'none'}
                    ><IonLabel>Выберите целевой статус</IonLabel></IonItem>



        </IonList>
    return <IonList>
        <IonPopover size={'cover'} animated={false} isOpen={open} onDidDismiss={() => setOpen(false)} keepContentsMounted={true} side={'top'} dismissOnSelect={true}>

            <IonList>
                {
                    availableStatusIds.length
                        ?availableStatusIds.map(nextStatusId =>
                            <StatusItem
                                        key={nextStatusId}
                                        lines={'none'}
                                        selected={nextStatusId === statusId}
                                        statusId={nextStatusId}
                                        onClick={ () => {
                                            onStatusItemClick(nextStatusId)
                                        }}
                            >

                            </StatusItem>
                        )
                        : <IonItem color={'medium'} lines={'none'} >
                            <IonLabel>Нет доступных статусов</IonLabel>
                    </IonItem>

                }

            </IonList>

        </IonPopover>

                <StatusItem
                {...props}
                button
                statusId={
                    statusId
                }
                lines={'none'}
                onClick={() => !disabled && setOpen(true)}
            >
                {children}
            </StatusItem>



    </IonList>


}

export default StatusSelector
