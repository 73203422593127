import useStatuses from "../../hooks/useStatuses";
import {IonAccordion, IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonList} from "@ionic/react";
import * as Icons from "ionicons/icons";
import React from "react";

import './StatusAccordion.scss'
const LinkedStatusesList = ({nextStatusId, useCard}) => {
    const {getStatusById} = useStatuses()
    const nextStatus = getStatusById(nextStatusId)
    const content = <>
        <IonItem lines={'full'} slot={'header'}>
            <IonIcon size={'small'} icon={Icons.ellipse} style={{
                color: nextStatus.color,
                marginRight: '8px'
            }}>

            </IonIcon>
        <IonLabel style={{fontWeight: 'bold'}}>{nextStatus.name} </IonLabel>
        {/*  <IonIcon slot={'end'} color={'primary'} icon={Icons.informationCircleOutline} style={{

        }}/>*/}
    </IonItem>
        <IonCardContent color={'light'} slot={'content'}>{nextStatus.description}</IonCardContent>
        {
            nextStatus.prevStatusesIds.length
                ? <IonList>
                    {nextStatus.prevStatusesIds.map(id => {
                        const prevStatus = getStatusById(id)
                        const isReversable = nextStatus.nextStatusesIds.includes(id)
                        return <IonItem lines={'none'} fill={'solid'}>

                            <IonIcon slot="start" size={'small'}
                                     icon={isReversable ? Icons.arrowUndo : Icons.arrowUp}
                                     style={{
                                         color: nextStatus.color
                                     }}
                            ></IonIcon>

                            <IonIcon size={'small'} icon={Icons.ellipse} style={{
                                color: prevStatus.color,
                                marginRight: '8px',
                            }}/>


                            <IonLabel>{prevStatus.name} </IonLabel>


                        </IonItem>
                    })
                    }
                </IonList>

                : null
        }


    </>
    if (useCard)
        return <IonCard>
            {content}
        </IonCard>

    return <IonAccordion>{content}</IonAccordion>
}
export default LinkedStatusesList
