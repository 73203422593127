import * as mobiscroll from '@mobiscroll/react'
import "./mobiscroll.4.scss";
import "./mobiscroll.react.scss";
import './mobiscroll.addons.css'
import * as luxon from 'luxon';
import { luxonTimezone } from '@mobiscroll/react';
window['luxon'] = luxon
luxonTimezone.luxon = luxon;
mobiscroll.setOptions({
    locale: mobiscroll.localeRu,
});